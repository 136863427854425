<!--
    描述：布局 --- 左侧菜单
    时间：2021-03-24
    Created by 王帅
-->
<template>
    <div class="app-leftMenu" :style="activeStyle" :class="{'collapse-menu': isCollapse}">
        <div class="top-btn">
            <!-- <img src="../../assets/images/logo_jk.png" alt="" v-if="!this.isCollapse"/>
            <img src="../../assets/images/logo_jk_small.png" alt="" v-else/> -->
            <span class="text-overflow">项目管理系统</span>
        </div>
        <div class="bottom-menu" :class="{ 'pd-menu': !isCollapse }">
            <el-scrollbar wrap-class="scrollbar-wrapper" class="h100">
                <el-menu
                    :collapse="isCollapse"
                    class="app-elMenu"
                    :default-active="activeMenu"
                    @select="handleClick"
                >
                    <template v-for="(item, index) in menuList">
                        <!-- 存在子菜单 -->
                        <el-submenu :index="item.name" v-if="filterShow(item)">
                            <template slot="title">
                                <svg-icon
                                    class="mr10 icon-color"
                                    :icon-class="item.icon ? item.icon : ''"
                                />
                                <span slot="title">{{ item.meta.title }}</span>
                            </template>

                            <!-- 二级菜单 -->
                            <template v-for="(m, n) in filterChildren(item.children)">
                                <el-submenu
                                    v-if="filterShow(m)"
                                    :key="'menu.children' + n"
                                    :index="m.name"
                                >
                                    <template slot="title">
                                        <!-- <svg-icon :icon-class="m.icon ? m.icon : ''" /> -->
                                        <span class="ml10">{{
                                            m.meta.title
                                        }}</span>
                                    </template>
                                </el-submenu>
                                <el-menu-item
                                    v-else
                                    :key="'menu.children' + n"
                                    :index="m.name"
                                >
                                    <svg-icon
                                        class="icon-color"
                                        :icon-class="m.icon ? m.icon : ''"
                                    />
                                    <span>{{ m.meta.title }}</span>
                                </el-menu-item>
                            </template>
                        </el-submenu>

                        <!-- 单项菜单 -->
                        <el-menu-item v-else :index="item.name">
                            <svg-icon
                                class="mr10 icon-color"
                                :icon-class="item.icon ? item.icon : ''"
                            />
                            <span slot="title">{{ item.meta.title }}</span>
                        </el-menu-item>
                    </template>
                </el-menu>
            </el-scrollbar>
        </div>

        <!-- 折叠按钮 -->
        <span
            class="collapse-btn"
            @click="handleCollapse"
            :title="isCollapse ? '点击展开' : '点击折叠'"
        >
            <i :class="isCollapse ? 'el-icon-d-arrow-right' : 'el-icon-d-arrow-left'"></i>
        </span>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
    name: "app-leftMenu",
    data() {
        return {};
    },
    methods: {
        ...mapActions("app", ["setCollapse", "setMenuName"]),

        // 切换折叠
        handleCollapse() {
            let value = !this.isCollapse;
            this.setCollapse(value);
        },

        // 菜单点击事件
        handleClick(index, indexPath) {
            let _path;
            let _index = this.routeList.findIndex((x) => x.componentName === index);
            // console.log(this.routeList);
            // console.log(_index);

            if (_index > -1) {
                // 如果是当前菜单
                if (index === this.$route.name) {
                    _path = "/redirect" + this.routeList[_index].path;
                } else {
                    _path = this.routeList[_index].path;
                }

                this.$router.push({
                    path: _path,
                });
            }
        },

        // children 过滤是否展示
        filterShow(item) {
            let status = item.children && item.children.length > 0;
            if (status) {
                status = item.children.filter((x) => x.isHidden !== 1).length > 0;
            }

            return status;
        },

        // children 过滤
        filterChildren(list) {
            let backArr = list.filter((x) => x.isHidden !== 1);
            return backArr;
        },
    },
    computed: {
        ...mapState("app", {
            isCollapse: (state) => state.isCollapse,
        }),

        ...mapState("menu", {
            activeRoutes: (state) => state.activeRoutes,
            routeList: (state) => state.routeList,
        }),

        // 当前激活的菜单
        activeMenu() {
            const route = this.$route;
            const { name } = route;

            let menuArr = [
				'ProjectInfo',
				'ProjectInfoView',
				'ProjectInfoLook',
				'CommissionDetails',
				'AuditLook',
				'AuditPlan',
				'AuditOpinion',
				'AuditMore',
				'ArchiveInfo',
                'BrowsingHistory',
                'FeeDialog',
                'ReportAudit'
			]

            let title = ''
            let activeName = ''

            if (menuArr.includes(name)) {
                activeName = JSON.parse(this.$getSessionItem("activeMenu")).name
                title = JSON.parse(this.$getSessionItem("activeMenu")).meta.title
            } else {
                activeName = name
                title = route.meta.title
            }

            this.setMenuName(title)
            return activeName;
        },

        // 菜单列表
        menuList() {
            let arr = this.activeRoutes.filter((x) => x.isHidden !== 1);
            return arr;
        },

        // 动态宽度
        activeStyle() {
            if (this.isCollapse) {
                return {
                    width: "60px",
                };
            }
        },
    },
};
</script>

<style lang="less">
.app-leftMenu {
    @btnHeight: 80px;
    position: relative;

    .top-btn {
        height: @btnHeight;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 10px;
        // border-bottom: 1px solid #dcdcdc;

        img {
            height: 40%;
            width: 89%;
        }
    }

    .bottom-menu {
        height: calc(~"100% - @{btnHeight}");
        transition: all 0.6s;
    }

    .pd-menu {
        // padding: 0 10px;
        padding: 0;
    }

    .collapse-btn {
        position: absolute;
        right: -9px;
        top: 30%;
        width: 10px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        box-shadow: 2px 0px 4px 0px rgba(0, 0, 0, 0.1);
        border-radius: 0px 100px 100px 0px;
        cursor: pointer;
        color: #60adf9;

        i {
            font-size: 12px;
        }
    }

    // --el-menu 样式
    .app-elMenu {
        border-right: none;
    }

    .el-menu--collapse {
        width: 60px;
    }

    .el-menu-item, .el-submenu__title {
        height: 38px;
        line-height: 38px;
        margin-bottom: 5px;
        color: #666;
    }

    .el-menu-item.is-active,
    .el-menu-item:focus {
        background-color: #f5f6fa;
        border-radius: 20px;
        color: #60adf9;
        .icon-color {
            color: #60adf9;
        }
    }

    .el-menu-item:hover {
        background-color: transparent;
        color: #60adf9;
        .icon-color {
            color: #60adf9;
        }
    }

    .el-submenu {
        .el-submenu__title:hover {
            background-color: transparent;
        }
        .el-menu-item {
            height: 38px;
            line-height: 38px;
            min-width: unset;
            padding: 0 20px 0 40px;

            &.is-active, &:focus {
                background-color: transparent;
            }
        }
    }
}
.collapse-menu {
    .top-btn {
        img {
            height: 40%;
            width: 60%;
        }
    }

    .el-menu-item .svg-icon {
        margin-left: 3px;
    }

    .el-menu-item.is-active, .el-menu-item:focus {
        border-radius: unset;
        
    }
}
.icon-color {
    color: #7A94B1;
}
</style>

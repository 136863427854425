import axios from 'axios'
import { Loading, Message } from 'element-ui'
import { getItem, setItem } from 'utils/storage'
import store from '@/store'

axios.defaults.withCredentials = false

// 创建一个 axios 实例
const origin = window.origin
const service = axios.create({
    baseURL: origin + process.env.VUE_APP_BASE_URL, // api 的 base_url
    // baseURL: '/test',    // (可在单个接口中覆盖)
    // baseURL: 'http://172.16.0.40:9999',    // (张磊)
    // baseURL: 'http://172.16.0.108:8091',   // (郑志会)
    // baseURL: 'http://172.16.0.109:8091',   // (陈实)
    // baseURL: 'http://172.16.0.115:8091',   // (崔北超)
    timeout: 600000, // request timeout(可在单个接口中覆盖)
    // withCredentials: true
})

// 是否加载 接口loading 动画
let loadingInstance = null

// 请求拦截器
service.interceptors.request.use(
    config => {
        // 如果设置了 展示（默认不展示）
        if (config.requestConfig && config.requestConfig.showLoading) {
            loadingInstance = Loading.service({
                lock: true,
                text: '数据加载中，请稍后...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.4)'
            })
        }

        if (config.requestConfig && config.requestConfig.timeout) {
            config.timeout = config.requestConfig.timeout
        }

        if (config.requestConfig && config.requestConfig.needTenantId) {
            config.data = Object.assign({
                tenantId: getItem('tenantId') || '',
            }, config.data)
        }


        // 请求加上 token (如果请求配置加上了 hideToken  则不带)
        let status = config.requestConfig && config.requestConfig.hideToken
        if (getItem('token') && !status) {
            config.headers['token'] = getItem('token')
        }

        if (getItem('tenantId')) {
            if (config.url != 'group1/upload') {
                config.headers['tenantId'] = getItem('tenantId')
            }
        }

        if (getItem('limitId')) {
            if (config.url != 'group1/upload') {
                config.headers['limitId'] = getItem('limitId')
            }
        }

        if (store.state.user.isMaintenance) {
            // 运维角色
            config.headers['publicIP'] = getItem('publicIP')
            config.headers['insideIP'] = getItem('insideIP')
            config.headers['os'] = getItem('os')
        }

        // 项目名称
        let menuName = store.state.app.curMenuName
        let headerId = config.requestConfig && config.requestConfig.hideHeaderId
        if (!headerId) {
            config.headers['_moduleId'] = encodeURI('经开-审计-主')
            config.headers['_menuId'] = encodeURI(menuName)
        }

        return config
    },
    err => {
        if (loadingInstance) loadingInstance.close()
        return Promise.reject(err)
    }
)

// 返回状态判断(添加响应拦截器)
service.interceptors.response.use(
    async (res) => {
        if (parseInt(res.data.operationType) === 2) {
            await sleep()
        }

        // 如果返回的是文件流, 直接返回 res
        if (res.config.responseType === 'blob') {
            return res
        }

        // 如果返回code = 0， 为正确 retCode(文件服务器)
        if (res.data.code === 0 || res.data.retcode === 0) {
            if (loadingInstance) loadingInstance.close()
            // Message.success(res.data.msg)
            // 如果是 操作类接口 ，提示消息
            if (parseInt(res.data.operationType) === 2 && res.data.msg !== '') {
                if (res.data.msg != 'faild') {
                    Message.success(res.data.msg)
                }
            }

            return res.data
        } else {
            // console.log(res)
            if (loadingInstance) loadingInstance.close()
            let errorMsg = ''
            let errorCode = ''
            if (res.data.result) {
                errorMsg = res.data.result.msg
                errorCode = res.data.result.code
            } else {
                errorMsg = res.data.msg
                errorCode = res.data.code
            }

            // 错误提示
            Message.error({
                showClose: true,
                dangerouslyUseHTMLString: true,
                message: errorMsg,
                // message: `接口地址：${res.config.url}, 错误信息：${errorMsg}`,
                duration: 3000
            })

            // token 失效，返回登录
            if (errorCode === 401) {
                store.dispatch('user/logOut', {}).then(() => {
                    let origin = window.location.origin
                    let pathname = window.location.pathname
                    window.location.href = `${origin}${pathname}#/login`
                })
            }
            // Message.error(errorMsg)
            return Promise.reject(res.data)
        }
    },
    err => {
        // console.log(err)
        if (loadingInstance) loadingInstance.close()
        return Promise.reject(err)
    }
)

function sleep() {
    return new Promise((resolve) => setTimeout(resolve, 1000))
}

export default service
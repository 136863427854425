<template>
	<div class="w-split-layout"
		 ref="wSplit">
		<!-- 水平面板 -->
		<div v-if="isHorizontal"
			 class="w-split-horizontal">
			<!-- 左侧面板 -->
			<div class="left-pane w-split-pane"
				 :style="{right: `${anotherOffset}%`}">
				<slot name="left" />
			</div>

			<!-- 拖动触发容器 -->
			<div class="w-split-trigger w-split-trigger-vertical"
				 @mousedown="handleMousedown"
				 :style="{left: `${offset}%`}">
				<div class="w-trigger-vertical">
					<div class="w-vertical-bar">
						<i class="w-trigger-bar-vertical"
						   v-for="n in 8"
						   :key="'trigger-vertical' + n"
						   v-once></i>
					</div>
				</div>
			</div>

			<!-- 右侧面板 -->
			<div class="right-pane w-split-pane"
				 :style="{left: `${offset}%`}">
				<slot name="right" />
			</div>
		</div>

		<!-- 垂直面板 -->
		<div v-else
			 class="w-split-vertical">
			<!-- 上方面板 -->
			<div class="top-pane w-split-pane"
				 :style="{bottom: `${anotherOffset}%`}">
				<slot name="top" />
			</div>

			<!-- 拖动触发容器 -->
			<div class="w-split-trigger w-split-trigger-horizontal"
				 :style="{top: `${offset}%`}">
				<div class="w-trigger-horizontal"
					 @mousedown="handleMousedown">
					<div class="w-horizontal-bar">
						<i class="w-trigger-bar-horizontal"
						   v-for="n in 8"
						   :key="'trigger-horizontal' + n"
						   v-once></i>
					</div>
				</div>
			</div>

			<!-- 下方面板 -->
			<div class="bottom-pane w-split-pane"
				 :style="{top: `${offset}%`}">
				<slot name="bottom" />
			</div>
		</div>
	</div>
</template>

<script>
/**
 * Events
 * @on-move-start
 * @on-moving 返回值：事件对象，但是在事件对象中加入了两个参数：atMin(当前是否在最小值处), atMax(当前是否在最大值处)
 * @on-move-end
 */
export default {
	props: {
		// 面板分割比例
		value: {
			type: [Number, String],
			default: 0.5
		},
		// 面板布局（垂直 vertical 还是 水平 horizontal）
		mode: {
			type: String,
			default: 'horizontal'
		},
		// 面板最小值
		min: {
			type: [Number, String],
			default: '40px'
		},
		// 面板最大值
		max: {
			type: [Number, String],
			default: '100px'
		}
	},
	data() {
		return {
			initOffset: 0, // 记录移动前的位置
			offset: 10,
			oldOffset: 0,
			isMoving: false,
			computedMin: 0,
			computedMax: 0,
			currentValue: 0.5
		}
	},
	methods: {
		// 计算百分比
		getPercent(current, total) {
			return parseFloat(current) / parseFloat(total)
		},
		// 获取临界值(最小值 最大值)
		getLimitValue(type) {
			// 获取当前容器的 宽度/高度
			let size = this.$refs.wSplit[this.offsetSize]

			// 如果 最大/最小值 是字符串（px）
			if (this.valueIsPx)
				return typeof this[type] === 'string'
					? this[type]
					: size * this[type]
			else
				return typeof this[type] === 'string'
					? this.getPercent(this[type], size)
					: this[type]
		},
		// 在两个值之间取  最大值
		getMax(value1, value2) {
			if (this.valueIsPx)
				return `${Math.max(parseFloat(value1), parseFloat(value2))}px`
			else return Math.max(value1, value2)
		},
		// 获取另一个面板的值
		getAnotherOffset(value) {
			let res = 0
			if (this.valueIsPx) {
				res =
					this.$refs.wSplit[this.offsetSize] -
					parseFloat(value) +
					'px'
			} else {
				res = 1 - value
			}
			return res
		},
		// mousemove 事件
		handleMove(e) {
			let pageOffset = this.isHorizontal ? e.pageX : e.pageY
			let offset = pageOffset - this.initOffset
			let layoutWidth = this.$refs.wSplit[this.offsetSize]

			// 其中一块的值
			let value = this.valueIsPx
				? `${parseFloat(this.oldOffset) + offset}px`
				: this.getPercent(
						layoutWidth * this.oldOffset + offset,
						layoutWidth
				  )

			// 另一块的值
			let anotherValue = this.getAnotherOffset(value)

			// 最大最小值判定
			if (parseFloat(value) <= parseFloat(this.computedMin))
				value = this.getMax(value, this.computedMin)
			if (parseFloat(anotherValue) <= parseFloat(this.computedMax))
				value = this.getAnotherOffset(
					this.getMax(anotherValue, this.computedMax)
				)
			e.atMin = this.value === this.computedMin
			e.atMax = this.valueIsPx
				? this.getAnotherOffset(this.value) === this.computedMax
				: this.getAnotherOffset(this.value).toFixed(5) ===
				  this.computedMax.toFixed(5)

			// 赋值改变
			this.offset = (value * 10000) / 100
			this.currentValue = value
			this.$emit('input', value)
			this.$emit('on-moving', e)
		},
		// trigger 鼠标按下事件
		handleMousedown(e) {
			this.initOffset = this.isHorizontal ? e.pageX : e.pageY
			this.oldOffset = this.currentValue
			this.isMoving = true
			// addEventListener 第三个参数， true 为捕获过程执行，自上而下， false 为冒泡过程执行，自下而上
			document.addEventListener('mousemove', this.handleMove, false)
			document.addEventListener('mouseup', this.handleUp, false)
			// 如果需要回调
			this.$emit('on-move-start')
		},
		// trigger 鼠标弹起事件
		handleUp() {
			this.isMoving = false
			document.removeEventListener('mousemove', this.handleMove, false)
			document.removeEventListener('mouseup', this.handleMove, false)
			this.$emit('on-move-end')
		},
		// 计算
		computeOffset() {
			this.$nextTick(() => {
				// 传过来的 最大最小值
				this.computedMin = this.getLimitValue('min')
				this.computedMax = this.getLimitValue('max')

				// 当前的值 占比
				let value = this.valueIsPx
					? this.getPercent(
							this.value,
							this.$refs.wSplit[this.offsetSize]
					  )
					: this.value

				// 另一个面板的值
				let anotherValue = this.getAnotherOffset(value)

				// 根据设定的最大最小值做限制
				if (parseFloat(value) <= parseFloat(this.computedMin))
					value = this.getMax(value, this.computedMin)
				if (parseFloat(anotherValue) <= parseFloat(this.computedMax))
					value = this.getAnotherOffset(
						this.getMax(anotherValue, this.computedMax)
					)

				// 当前 trigger 位置
				this.offset = (value * 10000) / 100
				this.currentValue = value
				this.$emit('input', value)
			})
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.computeOffset()
		})
		window.addEventListener('resize', this.computeOffset, false)
	},
	computed: {
		// 是否水平
		isHorizontal() {
			return this.mode === 'horizontal'
		},
		// 根据面板方向 获取 不同属性（宽度？高度）
		offsetSize() {
			return this.isHorizontal ? 'offsetWidth' : 'offsetHeight'
		},
		// 查看值是否是字符串类型
		valueIsPx() {
			return typeof this.value === 'string'
		},
		anotherOffset() {
			return 100 - this.offset
		}
	},
	watch: {
		// 监听分割值的变化
		value(val) {
			if (val !== this.currentValue) {
				this.currentValue = val
				this.computeOffset()
			}
		}
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.computeOffset, false)
	}
}
</script>

<style lang="less" scoped>
.w-split-layout {
	position: relative;
	width: 100%;
	height: 100%;

	.w-split-pane {
		position: absolute;
	}

	.w-split-trigger {
		position: absolute;
		z-index: 10;
	}

	.w-split-trigger-horizontal {
		width: 100%;
		height: 0;
		left: 0;
	}

	.w-split-trigger-vertical {
		width: 0;
		height: 100%;
		top: 0;
	}

	.w-trigger-bar-horizontal {
		height: 4px;
		width: 1px;
		background: rgba(23, 35, 61, 0.25);
		float: left;
		margin-left: 3px;
	}

	.w-trigger-bar-vertical {
		width: 4px;
		height: 1px;
		background: rgba(23, 35, 61, 0.25);
		float: left;
		margin-top: 3px;
	}

	.w-split-horizontal {
		.left-pane,
		.right-pane {
			top: 0;
			bottom: 0;
		}

		.left-pane {
			left: 0;
		}

		.right-pane {
			right: 0;
			padding-left: 6px;
		}

		.w-trigger-vertical {
			width: 6px;
			height: 100%;
			background: #f8f8f9;
			border: 1px solid #dcdee2;
			border-top: none;
			border-bottom: none;
			cursor: col-resize;
		}

		.w-vertical-bar {
			position: absolute;
			left: 1px;
			top: 50%;
			overflow: hidden;
			height: 32px;
			transform: translate(0, -50%);
		}
	}

	.w-split-vertical {
		.top-pane,
		.bottom-pane {
			left: 0;
			right: 0;
		}

		.top-pane {
			top: 0;
		}

		.bottom-pane {
			bottom: 0;
		}

		.w-trigger-horizontal {
			width: 100%;
			height: 6px;
			background: #f8f8f9;
			border: 1px solid #dcdee2;
			border-left: none;
			border-right: none;
			cursor: row-resize;
		}

		.w-horizontal-bar {
			position: absolute;
			top: 1px;
			left: 50%;
			overflow: hidden;
			width: 32px;
			transform: translate(-50%, 0);
		}
	}
}
</style>
// 项目工具类函数
import { MessageBox } from "element-ui";
import VUE_CONFIG from "../../vue.config";
import axios from "axios";
import { getItem, setItem } from "utils/storage";

// 转化 路由 component 对象
export function lazyLoading(url) {
    // return () => import(`@/${url}.vue`)
    return (resolve) => require([`@/${url}.vue`], resolve);
}

// 取地址栏参数
export function param2Obj(url) {
    const search = url.split("?")[1];
    if (!search) {
        return {};
    }
    return JSON.parse(
        '{"' +
        decodeURIComponent(search)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
        '"}'
    );
}

// 真实菜单接口数据 处理
export function addActualRoutes(backArr, arr) {
    arr.map((item, index) => {
        // 设置路由对象 参数
        let _menuObj = Object.assign({}, item);
        _menuObj.component = lazyLoading(item.componentPath);
        _menuObj.name = item.componentName;
        _menuObj.meta = {
            title: item.sname,
        };

        // 不需要children 字段
        if (_menuObj.children && _menuObj.children.length === 0) {
            delete _menuObj.children;
        }

        // 添加子路由
        if (item.children && item.children.length > 0) {
            _menuObj.redirect = item.children[0].path;
            _menuObj.children = [];
            addActualRoutes(_menuObj.children, item.children);
        }

        backArr.push(_menuObj);
    });

    return backArr;
}

// 路由提取到一级 返回数组
export function initRoutes(arr, parentName) {
    // 数组深拷贝
    let _data = JSON.parse(JSON.stringify(arr));
    let resArr = [];

    while (_data.length) {
        // 删除第一个元素并返回 删除的数据
        const node = _data.shift();
        if (node.children && node.children.length) {
            _data.push(...node.children);
        }
        delete node.children;
        if (node[parentName] !== null && node[parentName] !== undefined) {
            resArr.push(node);
        }
    }

    return resArr;
}

// 删除确认公共提示
export function deleteConfirm(text, type) {
    let textInfo = type ? text : `此操作将永久删除${text}, 是否继续?`;
    return new Promise((resolve, reject) => {
        MessageBox.confirm(textInfo, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
            center: true,
        })
            .then(() => {
                resolve(true);
            })
            .catch(() => {
                resolve(false);
            });
    });
}

// 全局公共下载函数
export function downloadFile(url) {
    let uploadUrl = "";
    // 本地环境代理后无法直接获取路径
    if (process.env.VUE_APP_UPLOAD_URL == "/uploadApi") {
        uploadUrl = VUE_CONFIG.devServer.proxy["/uploadApi"].target;
    } else {
        uploadUrl = process.env.VUE_APP_UPLOAD_URL;
    }
    window.open(uploadUrl + url, "_blank");
}

/* 时间转化为 yyyy-mm-dd 格式 */
export function dateFormat(type, val) {
    const date = new Date() || val;
    let result;
    if (type === "yyyy-mm-dd") {
        result =
            date.getFullYear() +
            "-" +
            pad(date.getMonth() + 1) +
            "-" +
            pad(date.getDate());
    } else {
        result =
            date.getFullYear() +
            "-" +
            pad(date.getMonth() + 1) +
            "-" +
            pad(date.getDate()) +
            " " +
            pad(date.getHours()) +
            ":" +
            pad(date.getMinutes()) +
            ":" +
            pad(date.getSeconds());
    }
    return result;
}

// 检测数字是 个位 还是 十位
export function pad(val, len) {
    val = String(val);
    len = len || 2;
    while (val.length < len) {
        val = "0" + val;
    }
    return val;
}

// 树结构转换 json
export function treeToJson(array, pid) {
    const list = [];
    // 声明cache变量，便于匹配是否有循环引用的情况
    let cache = [];
    if (array) {
        // 处理 对于 循环调用的对象的 转换
        let str = JSON.stringify(array, function (key, value) {
            if (typeof value === "object" && value !== null) {
                if (cache.indexOf(value) !== -1) {
                    // 移除
                    return;
                }
                // 收集所有的值
                cache.push(value);
            }
            return value;
        });
        cache = null; // 清空变量，便于垃圾回收
        // 数组深拷贝
        const _data = JSON.parse(str);
        while (_data.length) {
            // 删除第一个元素并返回 删除的数据
            const node = _data.shift();
            if (node.children && node.children.length) {
                _data.push(...node.children);
            }
            delete node.children;
            if (node[pid] !== null && node[pid] !== undefined) {
                list.push(node);
            }
        }
    }
    return list;
}

// 数组深拷贝
export function $deepCopy(source, type) {
    let sourceCopy;

    switch (type) {
        case "Array":
            sourceCopy = [];
            break;
        case "Object":
            sourceCopy = {};
            break;
        default:
            break;
    }

    for (var item in source) {
        switch (Object.prototype.toString.call(source[item])) {
            case "[object Array]":
                sourceCopy[item] = source[item];
                break;
            case "[object object]":
                sourceCopy[item] = this.$deepCopy(source[item], "object");
                break;
            default:
                sourceCopy[item] = source[item];
                break;
        }
    }

    return sourceCopy;
}

// 根据身份证计算年龄
export function getAgeById(strId) {
    var returnAge;
    if (strId.length == 18) {
        var birthYear = strId.substr(6, 4);
        var d = new Date();
        var nowYear = d.getFullYear();
        if (nowYear == birthYear) {
            returnAge = 0; //同年 则为0岁
        } else {
            var ageDiff = nowYear - birthYear; //年之差
            if (ageDiff > 0) {
                returnAge = ageDiff;
            } else {
                returnAge = null; //返回-1 表示出生日期输入错误 晚于今天
            }
        }
    } else {
        returnAge = null;
    }
    return returnAge;
}

// 根据身份证计算年龄、出生日期
export function getInfoById(strId) {
    let returnInfo = {
        age: null,
        year: null,
        month: null,
        day: null,
        sex: null,
        birthDate: null,
    };

    // 检测格式
    if (strId.length == 18) {
        // 出生年份
        returnInfo.year = strId.substr(6, 4);

        // 出生月份
        returnInfo.month = strId.substr(10, 2);

        // 出生日
        returnInfo.day = strId.substr(12, 2);

        // 日期格式
        returnInfo.birthDate =
            returnInfo.year + "-" + returnInfo.month + "-" + returnInfo.day;

        // 性别
        let sex = strId.substr(16, 1);
        returnInfo.sex = parseInt(sex) % 2 === 0 ? 0 : 1; // 1 男 0 女

        var d = new Date();
        var nowYear = d.getFullYear();
        if (nowYear == returnInfo.year) {
            returnInfo.age = 0; //同年 则为0岁
        } else {
            var ageDiff = nowYear - returnInfo.year; //年之差
            if (ageDiff > 0) {
                returnInfo.age = ageDiff;
            } else {
                returnInfo.age = null; //返回-1 表示出生日期输入错误 晚于今天
            }
        }
    }
    return returnInfo;
}

// 根据状态获取样式
export function getStatusStyle(data) {
    let msg = "";
    // 待受理  待提交 待付款 待归档 待结项 未提交
    let arr1 = [
        "1001026009",
        "1001026015",
        "1001026016",
        "1001026022",
        "1001026025",
        "1001026031",
    ];
    // 审核中  部分付款 作业中  归档审核中  结项审核中   履约中
    let arr2 = [
        "1001026001",
        "1001026017",
        "1001026021",
        "1001026023",
        "1001026026",
        "1011001",
        "1001026014",
        "1001026032",
    ];
    // 审核通过  已结清  已结项  已归档  已受理  委托已受理   委托协审已受理   内部审核通过  履约完毕
    let arr3 = [
        "1001026033",
        "1001026018",
        "1001026008",
        "1001026013",
        "1001026010",
        "1001026006",
        "1001026011",
        "1001026002",
        "1011002",
    ];
    // 已超付  已上传合同  已结算
    let arr4 = ["1001026019", "1001026020", "1011003"];
    // 过程记录
    let arr5 = ["1001026024"];
    // 驳回   委托协审已退审  已退审   归档被驳回  结项被驳回  内部审核被驳回  已撤回
    let arr6 = [
        "1001026034",
        "1001026012",
        "1001026007",
        "1001026028",
        "1001026027",
        "1001026003",
        "1001026004",
    ];
    // 已作废  归档被作废  结项被作废
    let arr7 = ["1001026005", "1001026029", "1001026030"];
    if (arr1.includes(data)) {
        msg = "ToBeOperating";
    }
    if (arr2.includes(data)) {
        msg = "operating";
    }
    if (arr3.includes(data)) {
        msg = "operated";
    }
    if (arr4.includes(data)) {
        msg = "operated2";
    }
    if (arr5.includes(data)) {
        msg = "toProcess";
    }
    if (arr6.includes(data)) {
        msg = "toRejected";
    }
    if (arr7.includes(data)) {
        msg = "toGiveUp";
    }
    return msg;
}

// 根据状态获取样式
export function getFormMonth(date, type) {
    let dateNow = new Date(date);
    let year = dateNow.getFullYear();
    let month = dateNow.getMonth();
    switch (type) {
        case "up":
            if (month == 0) {
                year -= 1;
                month = 12;
            }
            break;
        case "now":
            month = month + 1;
            break;
        case "down":
            if (month == 11) {
                year += 1;
                month = 1;
            } else {
                month = month + 2;
            }
            break;
    }
    month = month < 10 ? "0" + month : month;
    let lastYearMonth = year + "-" + month;
    return lastYearMonth;
}

// 导出Excel公用方法
export function exportExcel(url, params, fileName) {
    axios
        .post(url, params, {
            responseType: "arraybuffer",
            headers: {
                tenantId: getItem("tenantId") || "",
                token: getItem("token") || "",
                limitId: getItem("limitId") || "",
            },
        })
        .then((res) => {
            // 获取文件名  这里是后台使用了cache-control 放文件名称  Content-Disposition  axios不接受
            const fileName = decodeURI(
                res.headers['cache-control'].split('=')[1]
            )
            const link = document.createElement("a"); // 创建元素
            let blob = new Blob([res.data], {
                type: "application/vnd.ms-excel",
            });
            link.style.display = "none";
            link.href = URL.createObjectURL(blob); // 创建下载的链接
            //num++
            link.setAttribute("download", fileName); // 给下载后的文件命名
            document.body.appendChild(link);
            link.click(); // 点击下载
            document.body.removeChild(link); //  下载完成移除元素
            window.URL.revokeObjectURL(link.href); // 释放掉blob对象
        });
}

/**
 * @desc 函数防抖
 * @param fn 函数
 * @param timerDelay 延迟执行毫秒数
 * @param immediate true 表立即执行，false 表非立即执行
 */
export function debounce(fn, timerDelay, immediate) {
    let timer;
    return function () {
        let that = this;
        let args = arguments;

        if (timer) clearTimeout(timer);
        if (immediate) {
            var callNow = !timer;
            timer = setTimeout(() => {
                timer = null;
            }, timerDelay)
            if (callNow) fn.apply(that, args)
        } else {
            timer = setTimeout(function () {
                fn.apply(that, args)
            }, timerDelay);
        }
    }
}


// html2cancas 导出图片
import html2canvas from 'html2canvas'

export function html2canvasExport(el, name) {
    html2canvas(
        el,
        { scale: window.devicePixelRatio }
    ).then(canvas => {
        const imgUrl = canvas.toDataURL('image/png');
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = imgUrl;
        a.download = name;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    })
}


/**
 * @description: 获取外网ip
 */
export function getPublicIP() {
    axios.get('https://api.ipify.org').then(res => {
        if (res.data) {
            setItem('publicIP', res.data)
        }
    }).catch((e) => {
        setItem('publicIP', null)
    })
}

/**
 * @description: 获取内网ip
 * 谷歌浏览器默认隐藏了ip，需要设置为disabled
 * chrome://flags/#enable-webrtc-hide-local-ips-with-mdns
 */
export function getInsideIP() {
    if (typeof window != 'undefined') {
        var RTCPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection;
        if (RTCPeerConnection) (() => {
            var rtc = new RTCPeerConnection()
            rtc.createDataChannel('') //创建一个可以发送任意数据的数据通道
            rtc.createOffer(offerDesc => { //创建并存储一个sdp数据
                rtc.setLocalDescription(offerDesc)
            }, e => { })

            rtc.onicecandidate = (evt) => { //监听candidate事件
                if (evt.candidate) {
                    let ip_rule = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/
                    var ip_addr = ip_rule.exec(evt.candidate.candidate)[1] // 不设置浏览器的话，此地会报错
                    setItem('insideIP', ip_addr)
                }
            }
        })()
        else {
            console.log("没有找到")
        }
    }
}

/**
 * 获取操作系统
 */
export function browserRedirect() {
    var sUserAgent = navigator.userAgent;
    var isWin = (navigator.platform == "Win32") || (navigator.platform == "Windows");
    var isMac = (navigator.platform == "Mac68K") || (navigator.platform == "MacPPC") || (navigator.platform ==
        "Macintosh") || (navigator.platform == "MacIntel");
    if (isMac) return "Mac";
    var isUnix = (navigator.platform == "X11") && !isWin && !isMac;
    if (isUnix) return "Unix";
    var isLinux = (String(navigator.platform).indexOf("Linux") > -1);
    if (isLinux) return "Linux";
    if (isWin) {
        var isWin2K = sUserAgent.indexOf("Windows NT 5.0") > -1 || sUserAgent.indexOf("Windows 2000") > -1;
        if (isWin2K) return "Win2000";
        var isWinXP = sUserAgent.indexOf("Windows NT 5.1") > -1 || sUserAgent.indexOf("Windows XP") > -1;
        if (isWinXP) return "WinXP";
        var isWin2003 = sUserAgent.indexOf("Windows NT 5.2") > -1 || sUserAgent.indexOf("Windows 2003") > -1;
        if (isWin2003) return "Win2003";
        var isWinVista = sUserAgent.indexOf("Windows NT 6.0") > -1 || sUserAgent.indexOf("Windows Vista") > -1;
        if (isWinVista) return "WinVista";
        var isWin7 = sUserAgent.indexOf("Windows NT 6.1") > -1 || sUserAgent.indexOf("Windows 7") > -1;
        if (isWin7) return "Win7";
        var isWin10 = sUserAgent.indexOf("Windows NT 10") > -1 || sUserAgent.indexOf("Windows 10") > -1;
        if (isWin10) return "Win10";
    }
    return "other";
}

/**
 * http://net.ipcalf.com/  方法提取，显示本机内网ip地址
 * 谷歌浏览器默认隐藏了ip，需要设置为disabled
 * chrome://flags/#enable-webrtc-hide-local-ips-with-mdns
 */
export function ipcalfMethod() {
    var RTCPeerConnection = window.webkitRTCPeerConnection || window.mozRTCPeerConnection;

    if (RTCPeerConnection) (function () {
        var rtc = new RTCPeerConnection({ iceServers: [] });
        if (1 || window.mozRTCPeerConnection) {
            rtc.createDataChannel('', { reliable: false });
        };

        rtc.onicecandidate = function (evt) {
            if (evt.candidate) grepSDP("a=" + evt.candidate.candidate);
        };
        rtc.createOffer(function (offerDesc) {
            grepSDP(offerDesc.sdp);
            rtc.setLocalDescription(offerDesc);
        }, function (e) { console.warn("offer failed", e); });


        var addrs = Object.create(null);
        addrs["0.0.0.0"] = false;
        function updateDisplay(newAddr) {
            if (newAddr in addrs) return;
            else addrs[newAddr] = true;
            var displayAddrs = Object.keys(addrs).filter(function (k) { return addrs[k]; });
            setItem('insideIP', displayAddrs.join(" or perhaps ") || "n/a")
        }

        function grepSDP(sdp) {
            var hosts = [];
            sdp.split('\r\n').forEach(function (line) {
                if (~line.indexOf("a=candidate")) {
                    var parts = line.split(' '),
                        addr = parts[4],
                        type = parts[7];
                    if (type === 'host') updateDisplay(addr);
                } else if (~line.indexOf("c=")) {
                    var parts = line.split(' '),
                        addr = parts[2];
                    updateDisplay(addr);
                }
            });
        }
    })();
}
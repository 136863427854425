<!--
    描述：上传组件
    时间：2021-04-06
    Created by 王帅
-->
<template>
	<div class="newUpload-layout"
		 :class="{ 'newUpload-dsiabled': isDisabled }">
		<!-- 自定义文件列表 -->
		<template v-if="listType === 'text'">
			<template v-if="isDisabled && fileList.length === 0">
				<div>暂无附件</div>
			</template>
			<template v-show="!isDisabled || fileList.length !== 0">
				<el-upload class="upload-demo"
						   ref="upload"
						   action=""
						   drag
						   :list-type="listType"
						   :disabled="isDisabled"
						   :limit="maxNum"
						   :multiple="multiple"
						   :on-exceed="onExceed"
						   :on-change="onChange"
						   :file-list="fileList"
						   :auto-upload="false">
					<i class="el-icon-upload"></i>
					<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>

					<div slot="file"
						 class="file-item"
						 slot-scope="{ file }">
						<FileName :fileName="file.id ? file.fileName : file.name"
								  :filePath="file.filePath"
								  :fileSize="file.fileSize"
								  :showRemove="mode !== 'view'"
								  @doDelete="doDelete(file)"></FileName>
						<!-- <div class="item-left text-overflow">
							<em class="el-icon-document"></em>
							<a href="javascript:;"
							   @click="onPreview(file)">{{
                                file.id ? file.fileName : file.name
                            }}</a>
						</div>
						<div class="item-right">
							<em class="mr5 el-icon-zoom-in"
								title="查看"
								v-if="file.id || isPicture(file.raw)"
								@click="doView(file)"></em>
							<em class="mr5 el-icon-download"
								title="下载"
								v-if="file.id"
								@click="doDownload(file)"></em>
							<em class="mr5 el-icon-delete"
								title="删除"
								v-if="mode !== 'view'"
								@click="doDelete(file)"></em>
						</div> -->
					</div>

					<!-- 提示 -->
					<div slot="tip"
						 v-if="mode !== 'view'"
						 class="el-upload__tip file-tip">
						<p class="text-overflow">当前支持文件格式为: {{ acceptMsg }}</p>
					</div>
					<!-- 没有文件时的文字说明 -->
					<!-- <div v-if="fileLength === 0">暂无附件</div> -->
				</el-upload>
			</template>
		</template>

		<!-- 自定义文件列表 -->
		<template v-if="listType === 'list'">
			<template v-if="isDisabled && fileList.length === 0">
				<div>暂无附件</div>
			</template>
			<template v-show="!isDisabled || fileList.length !== 0">
				<el-upload class="upload-demo"
						   ref="upload"
						   action=""
						   :class="{ 'picture-list': listType === 'list', 'view-upload-style': mode == 'view' || isMaintenance }"
						   list-type="text"
						   :disabled="isDisabled"
						   :limit="maxNum"
						   :multiple="multiple"
						   :on-exceed="onExceed"
						   :on-change="onChange"
						   :file-list="fileList"
						   :auto-upload="false">
					<el-button slot="trigger"
							   icon="el-icon-upload"
							   v-if="mode !== 'view' && !isMaintenance"
							   type="text">上传文件</el-button>

					<div slot="file"
						 class="file-item"
						 slot-scope="{ file }">
						<FileName :fileName="file.id ? file.fileName : file.name"
								  :filePath="file.filePath"
								  :fileSize="file.fileSize"
								  :showRemove="mode !== 'view'"
								  @doDelete="doDelete(file)"></FileName>
						<!-- <div class="item-left text-overflow">
							<em class="el-icon-document"></em>
							<a href="javascript:;">{{
                                file.id ? file.fileName : file.name
                            }}</a>
						</div>
						<div class="item-right">
							<em class="mr5 el-icon-zoom-in"
								title="查看"
								v-if="file.id || isPicture(file.raw)"
								@click="doView(file)"></em>
							<em class="mr5 el-icon-download"
								title="下载"
								v-if="file.id"
								@click="doDownload(file)"></em>
							<em class="mr5 el-icon-delete"
								title="删除"
								v-if="mode !== 'view'"
								@click="doDelete(file)"></em>
						</div> -->
					</div>

					<!-- 提示 -->
					<div slot="tip"
						 v-if="mode !== 'view' && !isMaintenance"
						 class="el-upload__tip file-tip">
						<p class="text-overflow">当前支持文件格式为: {{ acceptMsg }}</p>
					</div>
				</el-upload>
			</template>
		</template>

		<!-- 合同专用 -->
		<template v-if="listType === 'table'">
			<div class="upload-title"
				 v-if="mode !== 'view' && !isMaintenance">
				<div class="label">文件上传:</div>
				<div class="upload-container">
					<template v-if="isDisabled && fileList.length === 0">暂无附件</template>
					<template v-show="!isDisabled || fileList.length !== 0">
						<el-upload class="upload-demo"
								   ref="upload"
								   action=""
								   :disabled="isDisabled"
								   :limit="maxNum"
								   :multiple="multiple"
								   :on-exceed="onExceed"
								   :on-change="onChange"
								   :show-file-list="false"
								   :auto-upload="false">
							<el-button type="text"><i class="el-icon-upload mr5"></i>点击上传</el-button>
						</el-upload>
					</template>
				</div>
			</div>
			<div class="upload-title"
				 v-if="mode !== 'view' && !isMaintenance">
				<div class="label"></div>
				<div class="upload-container text-overflow tips-text">
					当前支持文件格式为: {{ acceptMsg }}
				</div>
			</div>
			<div class="upload-table">
				<el-table border
						  :data="tableFile">
					<el-table-column type="index"
									 align="center"
									 header-align="center"
									 label="序号"
									 show-overflow-tooltip
									 width="60">
					</el-table-column>
					<el-table-column prop="name"
									 label="资料名称"
									 align="left"
									 header-align="center"
									 show-overflow-tooltip
									 min-width="230">
						<template slot-scope="scope">
							<span v-if="mode !== 'view'"
								  class="vxe-cell--label">{{ scope.row.fileName }}</span>
							<FileName v-else
									  :fileName="scope.row.fileName"
									  :filePath="scope.row.filePath"
									  :fileSize="scope.row.fileSize"></FileName>
							<!-- <a class="file-name vxe-cell--label" 
                               href="javascript:;" 
                               v-else
                               @click="doView(scope.row)">{{ scope.row.fileName }}</a> -->
						</template>
					</el-table-column>
					<el-table-column prop="redactUnit"
									 label="文件编制单位"
									 header-align="center"
									 min-width="220">
						<template slot="header"
								  slot-scope="scope">
							文件编制单位
							<el-tooltip effect="light">
								<div slot="content">
									文件编制单位： 所指文件的制作设计单位，<br>
									例如图纸设计方。
								</div>
								<svg-icon icon-class="wenhao"></svg-icon>
							</el-tooltip>
						</template>
						<template slot-scope="scope">
							<template v-if="!scope.row.id">
								<el-input size="small"
										  maxlength="50"
										  show-word-limit
										  v-model="scope.row.redactUnit"></el-input>
							</template>
							<template v-else>{{ scope.row.redactUnit }}</template>
						</template>
					</el-table-column>
					<el-table-column prop="captureDate"
									 label="创建日期"
									 min-width="210"
									 align="center"
									 header-align="center">
						<template slot-scope="scope">
							<template v-if="mode == 'add'">
								<el-date-picker v-model="scope.row.captureDate"
												type="datetime"
												:clearable="false"
												size="mini"
												style="width:180px"
												value-format="yyyy-MM-dd HH:mm:ss"
												:picker-options="endDatePicker"
												:disabled="captureDateDis(scope.row)"
												placeholder="选择日期时间">
								</el-date-picker>
							</template>
							<template v-else>{{ scope.row.captureDate }}</template>
						</template>
					</el-table-column>
					<el-table-column prop="createUser"
									 label="上传人"
									 width="180"
									 align="center"
									 header-align="center"
									 show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="upUnit"
									 label="上传单位"
									 width="200"
									 align="center"
									 header-align="center">
						<template slot-scope="scope">
							<el-tooltip v-if="scope.row.id"
										effect="dark"
										:content="scope.row.upUnit"
										placement="top">
								<div class="text-overflow w100">{{ scope.row.shortName }}</div>
							</el-tooltip>
							<span v-else>{{ scope.row.shortName }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="createDate"
									 label="上传时间"
									 width="180"
									 align="center"
									 header-align="center"
									 show-overflow-tooltip>
					</el-table-column>
					<el-table-column label="操作"
									 width="90"
									 align="center"
									 v-if="mode !== 'view'"
									 header-align="center"
									 show-overflow-tooltip>
						<template slot-scope="scope">
							<el-button class="table-btn"
									   size="small"
									   round
									   plain
									   type="danger"
									   :disabled="handleDis(scope.row)"
									   icon="el-icon-delete"
									   @click="tableDelete(scope.row)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</template>

		<!-- 图片模式 -->
		<template v-if="listType === 'picture'">
			<!-- <template v-if="!isDisabled && fileList.length === 0">
				<div>暂无附件</div>
			</template> -->
			<template v-show="fileList.length !== 0">
				<el-upload class="upload-demo picture-demo"
						   ref="upload"
						   :class="{'hideUpload': hideUpload}"
						   action=""
						   list-type="picture-card"
						   :disabled="isDisabled"
						   :limit="maxNum"
						   :multiple="multiple"
						   :on-exceed="onExceed"
						   :on-change="onChange"
						   :file-list="fileList"
						   :auto-upload="false">
					<i class="el-icon-upload"
					   v-if="mode !== 'view'"></i>
					<div class="el-upload__text">
						<em>{{ mode === 'view' ? '暂无图片' : '上传图片'}}</em>
					</div>

					<!-- 图片模式 -->
					<div slot="file"
						 slot-scope="{file}"
						 class="picture-box">
						<img class="el-upload-list__item-thumbnail"
							 :src="file.filePath ? `${uploadUrl}${file.filePath}` : file.url"
							 alt="">

						<span class="el-upload-list__item-actions">
							<em class="mr5 el-icon-zoom-in cursor-pointer"
								title="查看"
								@click="doView(file)"></em>
							<em class="mr5 el-icon-download cursor-pointer"
								title="下载"
								v-if="file.id"
								@click="doDownload(file)"></em>
							<em class="el-icon-delete cursor-pointer"
								title="删除"
								v-if="mode !== 'view' && !isDisabled"
								@click="doDelete(file)"></em>
						</span>
					</div>

					<!-- 提示 -->
					<div slot="tip"
						 v-if="mode !== 'view' && showTips"
						 class="el-upload__tip file-tip">
						<p class="text-overflow">当前支持文件格式为: {{ acceptMsg }}</p>
						<p class="text-overflow">上传图片大小不超过 {{ fileSize }}M</p>
					</div>
					<!-- 没有文件时的文字说明 -->
					<!-- <div v-if="fileLength === 0">暂无附件</div> -->
				</el-upload>
			</template>
		</template>

		<!-- 图片预览 -->
		<el-dialog :visible.sync="dialogObj.visible"
				   title="图片预览"
				   append-to-body
				   top="10vh"
				   center>
			<img width="100%"
				 :src="dialogObj.imgUrl"
				 alt="">
		</el-dialog>

		<!-- 上传进度 -->
		<el-dialog title="上传进度"
				   width="65%"
				   class="progressDialog"
				   :visible.sync="progressData.show"
				   :close-on-click-modal="false"
				   :close-on-press-escape="false"
				   :show-close="progressData.close"
				   :append-to-body="true"
				   @close="progressClose">
			<div>
				<el-row v-for="(i, index) in progressData.eventList"
						:key="'eventList' + index">
					<el-col :span="8">
						<div class="inputTitle">
							{{ i.name }}
						</div>
					</el-col>
					<el-col :span="16">
						<el-row class="progressBox"
								:gutter="20">
							<el-col :span="10"
									class="progressCol">
								<el-progress :text-inside="true"
											 :stroke-width="26"
											 :percentage="i.progress"></el-progress>
							</el-col>
							<el-col :span="12"
									class="progressInfo">
								{{ i.progressInfo }}&nbsp;
								<i class="el-icon-loading"
								   v-show="i.progressLoading"></i>
							</el-col>
						</el-row>
					</el-col>
				</el-row>
			</div>
		</el-dialog>

		<!-- 特殊字符弹窗 -->
		<el-dialog title="上传资料提示"
				   :visible.sync="specialShow"
				   center
				   width="800px"
				   :close-on-click-modal="false"
				   :before-close="specialClose"
				   append-to-body>
			<p class="mb20">
				<span v-for="(item, index) in unUploadList"
					  :key="item.uid + 'top' + index">
					{{ item.name }}
					<span v-if="index != unUploadList.length - 1">，</span>
				</span>包含
				<span v-if="specialStr.length > 0">特殊字符（{{ specialStr }}）</span>
				<span v-if="specialStr.length > 0 && spaceFlag">和</span>
				<span v-if="spaceFlag">空格</span>
				，无法在线预览。
			</p>
			<p class="mb20">
				点击确定后系统将自动去掉文件名中的
				<span v-if="specialStr.length > 0">特殊字符（{{ specialStr }}）</span>
				<span v-if="specialStr.length > 0 && spaceFlag">和</span>
				<span v-if="spaceFlag">空格</span>
				，直接上传。
			</p>
			<p>点击取消后，用户可重新修改文件名后上传。</p>
			<span slot="footer">
				<el-button class="btn"
						   @click="specialClose">取 消</el-button>
				<el-button class="btn"
						   type="primary"
						   :loading="specialLoading"
						   @click="specialSure">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import {
	uploadToWeb,
	saveFile,
	getFiles,
	deleteFile,
	getUserFiles,
	updateFileDate,
	getAuditFilesByBusId,
} from 'api/upload/upload'
import { dateFormat, $deepCopy } from 'utils/tools'
import dayjs from 'dayjs'
import SparkMD5 from 'spark-md5'
import VUE_CONFIG from '../../../vue.config.js'
import { mapState } from 'vuex'
export default {
	name: 'newUpload',
	props: {
		// 当前查看模式
		mode: {
			type: String,
			default: 'add',
		},
		// 最大文件上传个数
		maxNum: {
			type: Number,
			default: 10,
		},
		// 是否支持多选
		multiple: {
			type: Boolean,
			default: true,
		},
		// 接收的文件类型
		acceptType: {
			type: Array,
			default: () => {
				return []
			},
		},
		// 回显业务表id
		aid: {
			type: String,
			default: '',
		},
		// 业务表名称 --- 由后台表名确定
		tableName: {
			type: String,
			default: 'bus_pro_contract',
		},
		// assortment
		assortment: {
			type: String,
			default: '',
		},
		// 附件模式 (table 和 text 两种)
		listType: {
			type: String,
			default: 'text',
		},
		// 是否需要刷新页面
		needRefresh: {
			type: Boolean,
			default: true,
		},
		// 鼠标悬停显示大图
		hoverImg: {
			type: Boolean,
			default: false,
		},
		// 当前是否禁用编辑
		isDisabled: {
			type: Boolean,
			default: false,
		},
		// 宽度
		width: {
			type: String,
			default: '300px',
		},
		// 高度
		height: {
			type: String,
			default: '150px',
		},
		// 当前上传人
		createUser: {
			type: String,
			default: '管理员',
		},
		// 当前上传单位
		createCompany: {
			type: String,
			default: '',
		},
		// 当前上传单位简称
		shortName: {
			type: String,
			default: '',
		},
		// 上传文件分类 0项目问价 1标段文件 2合同文件
		uploadType: {
			type: String,
			default: '',
		},
		// getFile 需不需要传type
		needType: {
			type: Boolean,
			default: false,
		},
		// 是否展示文件格式提示
		showTips: {
			type: Boolean,
			default: false,
		},
		// 是否需要延迟加载
		lazyTime: {
			type: Number,
			default: 0,
		},
		noDelete: {
			type: Boolean,
			default: false,
		},
		// 文件大小
		fileSize: {
			type: Number,
			default: 100000000,
		},
		// 文件大小
		sizeType: {
			type: String,
			default: 'MB', // 10M
		},
		// 从哪获取
		getForm: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			fileList: [],
			addList: [],
			delList: [],
			// 文件预览对象
			dialogObj: {
				visible: false,
				imgUrl: '',
			},
			progressData: {
				show: false,
				close: false,
				eventList: [],
			},
			progressOverWatch: null,
			specialShow: false,
			unUploadList: [],
			specialStr: [],
			specialLoading: false,
			spaceFlag: false,
			endDatePicker: this.processDate(),
		}
	},
	mounted() {
		// 大小调整
		if (this.$refs.upload) {
			this.$refs.upload.$el.childNodes[0].style.width = this.width
			this.$refs.upload.$el.childNodes[0].style.height = this.height
		}

		if (this.$refs.upload && this.listType === 'picture') {
			this.$refs.upload.$el.style.width = this.width
			this.$refs.upload.$el.style.height = this.height

			this.$refs.upload.$el.childNodes[1].style.width = this.width
			this.$refs.upload.$el.childNodes[1].style.height = this.height
		}
		this.aid ? this.getFiles() : ''
	},
	methods: {
		// 特殊字符关闭按钮
		specialClose() {
			if (this.listType != 'table') {
				this.unUploadList.forEach((x) => {
					let _index = this.$refs.upload.uploadFiles.findIndex(
						(y) => y.uid == x.uid
					)
					this.$refs.upload.uploadFiles.splice(_index, 1)
				})
			} else {
				this.$refs.upload.uploadFiles =
					this.$refs.upload.uploadFiles.filter(
						(x) => x.specialStr.length == 0 && !x.spaceFlag
					)
			}
			this.unUploadList = []
			this.specialStr = []
			this.specialShow = false
			this.specialLoading = false
		},

		// 特殊字符确定
		specialSure() {
			this.specialLoading = true
			this.$refs.upload.uploadFiles.forEach((x, index) => {
				let str = /[`~!@#$%^+&;*|/?\s]/g
				if (str.test(x.name)) {
					// 将特殊字符替换为空
					x.name = x.name.replace(str, '')
					// 拷贝文件
					let fileRaw = this.$utils.clone(x.raw, true)
					let fileName = this.$utils.clone(x.name, true)
					// 将替换后的名称放入新的file
					let newFile = new window.File([x.raw], fileName, {
						type: fileRaw.type,
					})
					x.raw = newFile
					x.specialStr = []
					this.handleUpload(x, this.$refs.upload.uploadFiles)
				}
			})
			this.specialLoading = false
			this.specialShow = false
			this.unUploadList = []
			this.specialStr = []
			this.spaceFlag = false
			// this.specialClose()
		},

		// 文件列表变化事件
		onChange(file, fileList) {
			let that = this
			// 大小判断
			let divisionNum = this.sizeType == 'MB' ? 1024 * 1024 : 1024
			if (file.size / divisionNum > this.fileSize) {
				this.$message.warning(
					`温馨提示：上传图片大小不能超过 ${this.fileSize} ${this.sizeType}`
				)
				fileList.forEach((x, index) => {
					if (x.uid == file.uid) {
						fileList.splice(index, 1)
					}
				})
				return
			}

			// 检测文件名是否包含非法字符
			let _checkUnicode = true,
				_codeArr = []
			file.specialStr = []
			this.unicodeArr.map((x) => {
				if (file.name.includes(x)) {
					_checkUnicode = false
					if (x == ' ') {
						that.spaceFlag = true
						file.spaceFlag = true
					} else {
						file.specialStr.push(x)
						file.spaceFlag = false
					}
					let obj = that.unUploadList.find((y) => y.uid == file.uid)
					if (!obj) {
						that.unUploadList.push(file)
					}
				}
			})

			if (!_checkUnicode) {
				setTimeout(() => {
					let arr = []
					that.unUploadList.map((x) => {
						arr = arr.concat(x.specialStr)
					})
					that.specialStr = that.$utils.uniq(arr).join('、')
					that.specialList = fileList
					that.specialShow = true
				}, 300)
				return
			} else {
				that.handleUpload(file, fileList)
			}

			return
		},

		// 处理上传
		handleUpload(file, fileList) {
			let that = this
			// 进度条相关
			this.progressData.eventList = []
			fileList.map((i) => {
				// 文件名不包含非法字符
				// let _unicode = this.unicodeArr.some((x) => i.name.includes(x))
				// if (!i.id && !_unicode) this.progressData.eventList.push(i)
				if (!i.id && i.specialStr.length === 0) {
					this.progressData.eventList.push(i)
				}
			})
			this.progressData.eventList.map((i) => {
				i.progress = 0
				i.progressLoading = true
			})

			// 文件本身
			let fileReader = new FileReader()

			//此处打印file可看到file下的raw为文件属性
			var dataFile = file.raw
			var spark = new SparkMD5.ArrayBuffer()
			//获取文件二进制数据
			fileReader.readAsArrayBuffer(dataFile)

			//异步执行函数
			fileReader.onload = function (e) {
				spark.append(e.target.result)
				let md5 = spark.end()
				file.raw.md5 = md5

				let curType = file.name.substring(
					file.name.lastIndexOf('.') + 1
				)
				if (that.checkType(curType)) {
					// 如果不存在id 是添加情况
					if (!file.id) {
						// 查看是否存在 同样内容的文件
						let _exist1 = that.fileList.find(
							(x) => x.md5 === file.raw.md5
						)
						let _exist2 = that.addList.find(
							(x) => x.md5 === file.raw.md5
						)

						if (_exist1) {
							let text = `已存在与${_exist1.fileName}相同内容的文件，敬请知悉！`
							that.$message({
								showClose: true,
								message: text,
								type: 'info',
								duration: 3000,
							})
						}

						if (_exist2) {
							let text = `已存在与${_exist2.fileName}相同内容的文件，敬请知悉！`
							that.$message({
								showClose: true,
								message: text,
								type: 'info',
								duration: 3000,
							})
						}

						let fileRaw = that.$utils.clone(file.raw, true)
						let fileName = that.$utils.clone(fileRaw.name, true)
						fileName = fileName.replace(/[~!@#$%^&*|/?]/g, '')
						let newFile = new window.File([file.raw], fileName, {
							type: fileRaw.type,
						})
						newFile.uid = fileRaw.uid
						newFile.md5 = fileRaw.md5
						let obj = {
							fileName: file.name,
							unit: '',
							uid: file.uid,
							md5: md5,
							createUser: that.createUser,
							upUnit: that.createCompany,
							shortName: that.shortName,
							type: that.uploadType,
							createDate: dayjs().format('YYYY-MM-DD HH:mm:ss'),
							captureDate: dayjs(
								file.raw.lastModifiedDate
							).format('YYYY-MM-DD HH:mm:ss'),
							file: newFile,
							source: that.uploadType === '0' ? '1' : '',
						}
						that.addList.push(obj)
					}
				} else {
					let _index = that.$refs.upload.uploadFiles.findIndex(
						(x) => x.uid === file.uid
					)
					that.$refs.upload.uploadFiles.splice(_index, 1)
					setTimeout(() => {
						let pIndex = that.progressData.eventList.findIndex(
							(x) => x.uid === file.uid
						)
						that.progressData.eventList.splice(pIndex, 1)
					}, 100)
				}
			}
		},

		// 检测所选文件是否符合格式
		checkType(curType) {
			if (this.acceptType.length > 0) {
				if (
					this.acceptType.find(
						(x) => x === curType || x === curType.toUpperCase()
					)
				) {
					return true
				} else {
					this.$message.warning('文件格式不对，请重新选择!')
					return false
				}
			} else {
				return true
			}
		},

		// 文件上传方法 --- 上传数据库
		fileUpload(uploadId, item, params, projectId, index) {
			// 在有上传数据的情况下
			let _aid = uploadId ? uploadId : this.aid

			let dataParams = {
				path: params.path,
				md5: params.md5,
				size: params.size,
				aid: _aid,
				tableName: this.tableName,
				assortment: this.assortment,
				source: ['0', '4', '99'].includes(this.uploadType) ? '1' : '',
				projectId: projectId,
				type: this.uploadType == '99' ? '99' : '4',
				busId: ['0', '4', '99'].includes(this.uploadType) ? _aid : '',
				upUnit: item.upUnit,
				shortName: item.shortName,
				captureDate: item.captureDate,
			}

			if (this.listType === 'table') {
				dataParams.redactUnit = item.redactUnit //文件编制单位
				dataParams.upUnit = item.upUnit //上传单位
				dataParams.shortName = item.shortName //上传单位简称
				dataParams.type = item.type //文件分类
				dataParams.source = item.source
				dataParams.projectId = projectId || ''
				dataParams.busId = _aid //文件分类
			}
			saveFile(dataParams)
				.then((res) => {
					// this.$message.success('附件上传成功！')
					// this.doRefresh()
					if (this.listType === 'picture') {
						this.addList = []
						this.fileList = []
						this.getFiles()
					}

					this.progressData.eventList[index].progressInfo = '上传成功'
					this.progressData.eventList[index].progressLoading = false
				})
				.catch((err) => {
					// this.$message.error('附件上传数据库失败！')
					// this.doRefresh()
					if (this.progressData.eventList.length > 0) {
						this.progressData.eventList[index].progressInfo =
							'上传失败, 请关闭后重试'
						this.progressData.eventList[
							index
						].progressLoading = false
					}
				})
		},

		// 文件上传到服务器
		fileSend(aid, projectId, callBackFn, errorFn, fileInfo) {
			// 进度条相关
			if (fileInfo) {
				this.progressData.eventList = fileInfo.fileList
				this.progressData.eventList.map((i) => {
					i.progress = 0
					i.progressLoading = true
				})
				// this.addList = fileInfo.fileList
			}

			this.progressOverWatch = setInterval(() => {
				let over = true
				this.progressData.eventList.map((i) => {
					if (i.progressLoading) {
						over = false
					}
				})
				if (over) {
					this.progressData.show = false
					this.progressData.eventList = []
					clearInterval(this.progressOverWatch)
					if (callBackFn) {
						setTimeout(() => {
							callBackFn()
						}, 1000)
					}
				}
			}, 500)

			// 附件信息
			let that = this
			let arr = []
			if (this.listType == 'table') {
				arr = this.tableFile.filter((x) => !x.id)
			} else {
				arr = this.addList
			}
			this.updateFileDate()
			if (arr.length > 0) {
				if (this.listType === 'table') {
					let list = arr.filter((x) => !x.captureDate)
					if (list.length > 0) {
						this.$message.warning('请完善创建时间！')
						errorFn()
						return
					}
				}
				return new Promise((resolve, reject) => {
					for (let [i, item] of arr.entries()) {
						let formData = new FormData()
						formData.append('file', item.file)
						formData.append('path', dateFormat('yyyy-mm-dd'))
						formData.append('output', 'json')
						that.progressData.show = true
						// console.log(this.progressData.eventList)
						// this.progressData.eventList[0].progressInfo =
						// '正在合并文件并等待服务器回应, 请稍后....'
						// return false
						uploadToWeb(formData, that.progressFn, i)
							.then((res) => {
								// console.log(res)
								that.fileUpload(aid, item, res, projectId, i)
								// this.uploadMessage(true)
								resolve(true)
							})
							.catch((err) => {
								that.$message.error('附件上传服务器失败！')
								that.progressData.close = true
								that.$emit('loadingFalse')
								// that.doRefresh()
								reject(false)
							})
					}
				})
				// this.$refs.upload.submit()
			} else {
				// this.doRefresh()
				if (callBackFn) {
					callBackFn()
				}
			}
		},

		// 上传进度函数
		progressFn(progressEvent, index) {
			if (this.progressData.eventList.length > 0) {
				this.progressData.eventList[index].progress =
					((progressEvent.loaded / progressEvent.total) * 100) | 0
				if (this.progressData.eventList[index].progress === 100) {
					this.progressData.eventList[index].progressInfo =
						'正在合并文件并等待服务器回应, 请稍后....'
				} else {
					this.progressData.eventList[index].progressInfo = '正在上传'
				}
				let data = this.progressData.eventList[index]
				this.$set(this.progressData.eventList, index, data)
			}
		},

		// 进度条弹窗关闭
		progressClose() {
			this.progressData.close = false
		},

		// 文件超出最大个数事件
		onExceed() {
			this.$message.warning('当前最多可上传' + this.maxNum + '个文件！')
		},

		// 清除文件列表
		clearFiles() {
			this.$refs.upload.clearFiles()
			this.addList = []
			this.delList = []
		},

		// 数据回显
		getFiles(id) {
			let params = {
				aid: id ? id : this.aid,
				tableName: this.tableName,
				assortment: this.assortment,
				source: ['0', '4', '99'].includes(this.uploadType) ? '1' : '',
			}
			if (this.uploadType != '') {
				params.type = this.uploadType
			}

			if (this.listType === 'picture') {
				getUserFiles(params)
					.then((res) => {
						this.fileList = res.data
					})
					.catch((err) => {
						this.fileList = []
					})
			} else {
				if (this.getForm != '') {
					delete params.type
					getAuditFilesByBusId(params)
						.then((res) => {
							this.fileList = res.data
						})
						.catch((err) => {
							this.fileList = []
						})
					return
				}
				getFiles(params)
					.then((res) => {
						this.fileList = res.data
					})
					.catch((err) => {
						this.fileList = []
					})
			}
		},

		// 下载文件
		doDownload(file) {
			if (file.id) {
				window.open(process.env.VUE_APP_UPLOAD_URL + file.filePath)
			}
		},

		// 删除文件
		doDelete(file) {
			let _index = this.$refs.upload.uploadFiles.findIndex(
				(x) => x.uid === file.uid
			)
			// 如果有ID 弹窗提示
			if (file.id) {
				this.$confirm('确认要删除当前附件吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
				})
					.then(() => {
						let _id =
							this.listType === 'picture'
								? file.id
								: file.fileSummaryId
						deleteFile({ id: _id })
							.then((res) => {
								this.$message.success('删除成功!')
								this.$refs.upload
									? this.$refs.upload.uploadFiles.splice(
											_index,
											1
									  )
									: ''

								this.fileList.splice(
									this.fileList.findIndex(
										(x) => x.uid === file.uid
									),
									1
								)
							})
							.catch((err) => {})
					})
					.catch(() => {})
			} else {
				// 否则直接删除
				this.addList.splice(
					this.addList.findIndex((x) => x.uid === file.uid),
					1
				)
				this.$refs.upload.uploadFiles.splice(_index, 1)
			}
		},

		// 查看文件
		doView(file) {
			if (parseInt(file.fileSize) / 1024 / 1024 > 10) {
				this.$message.warning('该附件过大，请下载后预览。')
				return
			}
			if (file.id && !this.isPicture(file)) {
				let uploadUrl = ''
				// 本地环境代理后无法直接获取路径
				if (process.env.VUE_APP_UPLOAD_URL == '/uploadApi') {
					uploadUrl = VUE_CONFIG.devServer.proxy['/uploadApi'].target
				} else {
					uploadUrl = process.env.VUE_APP_UPLOAD_URL
				}

				window.open(
					this.$previewUrl + uploadUrl + file.filePath,
					'_blank'
				)
				return
			}
			this.dialogObj.imgUrl = file.filePath
				? this.$uploadUrl + file.filePath
				: this.getUrl(file.raw)
			this.dialogObj.visible = true
		},

		// 获取图片地址
		getUrl(file) {
			var url = null
			if (window.createObjectURL != undefined) {
				url = window.createObjectURL(file)
			} else if (window.URL != undefined) {
				url = window.URL.createObjectURL(file)
			} else if (window.webkitURL != undefined) {
				url = window.webkitURL.createObjectURL(file)
			}
			return url
		},

		// 预览
		onPreview(file) {
			// 检查如果是图片格式  直接打开弹窗
			if (this.isPicture(file)) {
				this.doView(file)
				return
			}

			// 如果不是图片 也没上传
			if (!file.id) {
				return
			}
			let uploadUrl = ''
			// 本地环境代理后无法直接获取路径
			if (process.env.VUE_APP_UPLOAD_URL == '/uploadApi') {
				uploadUrl = VUE_CONFIG.devServer.proxy['/uploadApi'].target
			} else {
				uploadUrl = process.env.VUE_APP_UPLOAD_URL
			}

			window.open(this.$previewUrl + uploadUrl + file.filePath, '_blank')
		},

		// 检查文件格式 是否为图片
		isPicture(file) {
			let picArr = ['JPG', 'JPEG', 'PNG', 'GIF', 'SVG', 'BMP']
			let fileTypeArr = file.id
				? file.fileName.split('.')
				: file.name.split('.')
			let fileType = fileTypeArr[fileTypeArr.length - 1].toUpperCase()

			return picArr.includes(fileType)
		},

		// 判断创建时间可不可以修改
		captureDateDis(data) {
			// 判断可不可以修改创建时间 首先是上传完的
			if (data.id) {
				// 先判断是不是自己上传的
				if (data.createUserid != this.userInfo.id) {
					// 不是自己上传的不给修改
					return true
				} else {
					// 是自己上传的在判断是不是项目内的
					return data.type == 2
				}
			} else {
				return false
			}
		},

		// 删除文件
		tableDelete(file) {
			let _index = this.$refs.upload.uploadFiles.findIndex(
				(x) => x.uid === file.uid
			)
			// 如果有ID 弹窗提示
			if (file.id) {
				this.$confirm('确认要删除当前附件吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
				})
					.then(() => {
						deleteFile({ id: file.fileSummaryId })
							.then((res) => {
								this.$message.success('删除成功!')
								this.$refs.upload.uploadFiles.splice(_index, 1)
								this.fileList.splice(
									this.fileList.findIndex(
										(x) => x.id === file.id
									),
									1
								)

								if (
									this.progressData.eventList.findIndex(
										(x) => x.uid === file.uid
									) > -1
								) {
									this.progressData.eventList.splice(
										this.progressData.eventList.findIndex(
											(x) => x.uid === file.uid
										),
										1
									)
								}
							})
							.catch((err) => {})
					})
					.catch(() => {})
			} else {
				// 否则直接删除
				this.addList.splice(
					this.addList.findIndex((x) => x.uid === file.uid),
					1
				)
				this.progressData.eventList.splice(
					this.progressData.eventList.findIndex(
						(x) => x.uid === file.uid
					),
					1
				)
				this.$refs.upload.uploadFiles.splice(_index, 1)
			}
		},

		// 获取表格模式下 全部的 data 数据
		getTableFileList() {
			return this.tableFile || []
		},

		// table 模式上传后就不准删除
		handleDis(data) {
			if (!data.id) {
				return false
			}
			// （仅用于规划图）
			if (this.noDelete) {
				return !!data.id
			}
			// 先判断是不是自己上传的
			if (data.createUserid != this.userInfo.id) {
				// 不是自己上传的不能删除
				return true
			} else {
				// 是自己上传的可以删除但是不能删除项目内上传的 type 2 是项目内上传的
				if (this.getForm != '') {
					return data.type == 2
				}
				return false
			}
		},

		// 更新创建时间
		updateFileDate() {
			let sysFiles = []
			this.tableFile.forEach((x) => {
				if (x.id) {
					let file = { id: x.id, captureDate: x.captureDate }
					sysFiles.push(file)
				}
			})
			if (sysFiles.length > 0) {
				updateFileDate({
					sysFiles,
				}).then((res) => {})
			}
		},

		// 结束时间的 pickOption
		processDate() {
			return {
				disabledDate(time) {
					return time.getTime() > Date.now()
				},
			}
		},
	},
	computed: {
		// 当前文件列表数量
		fileLength() {
			return this.$refs.upload ? this.$refs.upload.uploadFiles.length : 0
		},

		// 非法字符集
		unicodeArr() {
			return ['`', '~', '!', '@', '#', '$', '%', '^', '&', '+', ';', ' ']
		},

		// 支持的文件格式 文字
		acceptMsg() {
			return this.acceptType.length > 0
				? JSON.stringify(this.acceptType)
				: '所有格式文件'
		},

		tableFile() {
			let arr = $deepCopy(this.fileList, 'Array')
			arr = arr.concat($deepCopy(this.addList, 'Array'))
			return arr
		},

		// 上传接口地址
		uploadUrl() {
			return this.$uploadUrl
		},

		// 是否隐藏上传
		hideUpload() {
			return (
				this.addList.length >= this.maxNum ||
				this.fileList.length >= this.maxNum
			)
			// return this.$refs.upload ? this.$refs.upload.uploadFiles.length >= this.maxNum : false
		},

		...mapState('app', {
			projectId: (state) => state.projectId,
		}),
		...mapState('user', {
			// 运维角色
			isMaintenance: (state) => state.isMaintenance,
			userInfo: (state) => state.userInfo,
		}),
	},
	watch: {
		aid(val) {
			if (val === '' && this.fileList.length > 0) {
				this.fileList = []
			}
			if (val !== '' && val) {
				setTimeout(() => {
					this.getFiles()
				}, this.lazyTime)
			}
		},
	},
	beforeDestroy() {
		clearInterval(this.progressOverWatch)
	},
}
</script>

<style lang="less">
.newUpload-layout {
	.el-upload-dragger {
		width: 100%;
		height: 100%;

		.el-icon-upload {
			margin: 6% 0 4%;
		}
	}

	.file-item {
		display: flex;
		justify-content: space-between;
		padding: 0 5px;
		.item-left {
			width: 80%;
			padding: 0 5px;
			box-sizing: border-box;
		}
		.item-right {
			width: 20%;
			text-align: right;
			em {
				cursor: pointer;
			}
		}
	}

	.upload-title {
		height: 36px;
		line-height: 36px;
		display: flex;
		font-size: 14px;
		color: #606266;

		.label {
			width: 80px;
			text-align: left;
		}

		.tips-text {
			font-size: 12px;
		}

		.upload-container {
			flex: 1;
		}

		.el-upload {
			text-align: left;
		}
	}

	.file-name {
		color: #60adf9;
		text-decoration: underline;
	}

	.picture-demo {
		overflow: hidden;
		.el-upload--picture-card {
			width: 100%;
			height: 100%;
			display: flex;
			line-height: unset;
			flex-direction: column;
			justify-content: center;
			opacity: 1;
			transition: all 1s ease;
		}

		.el-upload-list--picture-card {
			opacity: 0;
			transition: all 1s ease;
		}
		.el-upload-list__item {
			width: 100%;
			height: 100%;
			margin: 0;
		}
		.picture-box {
			width: 100%;
			height: 100%;
		}

		img {
			width: 100%;
			height: 100%;
		}

		.el-icon-upload {
			color: #c0c4cc;
			font-size: 30px;
			line-height: 30px;
			margin: 0;
		}

		.el-upload__text {
			font-size: 12px;
			color: #3f9dff;
		}
	}
}

.newUpload-dsiabled {
	.el-upload-dragger {
		cursor: default;
		background-color: #f5f5f5;
		border-color: transparent;
	}

	.el-upload {
		cursor: not-allowed;
		display: none;
	}
}

.hideUpload {
	.el-upload--picture-card {
		opacity: 0 !important;
		// display: none !important;
	}

	.el-upload-list--picture-card {
		display: flex;
		opacity: 1 !important;
	}

	.el-upload {
		display: none;
	}
}
.view-upload-style {
	.el-upload {
		display: none !important;
	}
}
.btn {
	padding: 0 50px !important;
}
</style>

/**
* 描述：上传接口管理
* 录入人： 王帅
*/
import request from '../request.js'
let baseUrl = process.env.VUE_APP_BASE_URL
// let baseUrl = 'http://172.16.0.14:8090'


// 上传文件到 服务器
export const uploadToWeb = function (data, progressFn, index, params) {
    return request({
        baseURL: process.env.VUE_APP_UPLOAD_URL,
        url: 'group1/upload',
        method: 'post',
        headers: {
            'Content-type': 'multipart/form-data'
        },
        onUploadProgress: function (progressEvent) {
            if (progressFn) {
                progressFn(progressEvent, index)
            }
        },
        requestConfig: {
            timeout: 1000000000000,
            hideToken: true,
            hideHeaderId: true,
            params: params,
            index: index
        },
        data
    })
}

// 上传到 数据库 --- 李开明
export const saveFile = function (data) {
    return request({
        baseUrl: baseUrl,
        url: 'sysFile/saveFile',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: true
        },
    })
}

// ---- 用户管理 -- 附件
/**
* 附件 -- 获取
* 接口管理员： 俞翔东
* 参数：
{
    "userId": ''    // --人员id
}
*/
export const getUserFiles = function (data) {
    return request({
        url: 'sysFile/getUserBasicInfoFiles',
        method: 'post',
        data
    })
}

// 获取附件回显 --- 李开明
export const getFiles = function (data) {
    return request({
        baseUrl: baseUrl,
        url: 'sysFile/getFiles',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: true
        },
    })
}

// 根据文件id删除文件 (删除数据库) --- 李开明
export const deleteFile = function (data) {
    return request({
        baseUrl: baseUrl,
        url: 'sysFile/deleteFile',
        method: 'post',
        data
    })
}

// 委托报审资料获取（项目资料） --- 崔北超
export const getProjectFiles = function (data) {
    return request({
        baseUrl: baseUrl,
        url: 'busAuditFile/getProjectFiles',
        method: 'post',
        data
    })
}

/**
* 描述：获取项目资料
* 接口管理员：崔北超
* 参数： 
{
    projectId: '',          // 项目ID(有项目时再传，没有为空)
}
*/
export const getProjectFileData = function (data) {
    return request({
        url: 'busProjectFile/getFiles',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: false
        },
    })
}

/**
* 附件 -- 修改创建时间
* 接口管理员： 崔北超
* 
*/
export const updateFileDate = function (data) {
    return request({
        url: 'sysFile/updateFileDate',
        method: 'post',
        data
    })
}
/**
* 附件 -- 获取
* 接口管理员： 崔北超
* 参数：
{
}
*/
export const getAuditFilesByBusId = function (data) {
    return request({
        url: '/sysFile/getAuditFilesByBusId',
        method: 'post',
        data
    })
}


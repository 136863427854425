<!--
    描述：布局页面
    时间：2021-03-24
    Created by 王帅
-->
<template>
	<div class="app-layout">
		<!-- 顶部 -->
		<Header></Header>

		<!-- 左侧菜单 -->
		<LeftMenu></LeftMenu>

		<!-- 主体部分 -->
		<div class="app-main">
			<!-- 面包屑 -->
			<BreadCrumb></BreadCrumb>

			<div class="back-btn"
				 v-show="isShow"
				 @click="goArea">
				<img src="@/assets/images/main-back.png"
					 alt="">
			</div>
			<el-button type="primary"
					   class="go-back-btn"
					   v-show="isMain"
					   @click="goback">
				返回
			</el-button>

			<!-- 内容 -->
			<div class="app-main-container">
				<transition name="fade"
							mode="out-in">
					<router-view />
				</transition>
			</div>
		</div>
	</div>
</template>

<script>
import Header from './components/Header'
import LeftMenu from './components/LeftMenu'
import BreadCrumb from './components/BreadCrumb'
import { mapActions } from 'vuex'
export default {
	name: 'Layout',
	components: {
		Header,
		LeftMenu,
		BreadCrumb,
	},
	data() {
		return {}
	},
	methods: {
		...mapActions('app', ['setIsBack']),
		// 返回区域块
		goArea() {
			let menu1 = ['/InvestmentManagement', '/VisualProcess']
			let menu2 = ['/Tripartite', '/BasicData', '/AuditReport']
			let menu3 = ['/projectMap', '/planMap', '/wisdom-site']
			let path = this.$route.path
			let type = ''
			if (menu1.includes(path)) {
				type = 2
			}
			if (menu2.includes(path)) {
				type = 3
			}
			if (menu3.includes(path)) {
				type = 4
			}
			this.$router.push({
				name: 'SelectType',
				params: {
					type,
				},
			})
		},

		// 返回上一级
		goback() {
			let obj = this.$getSessionItem('activeMenu')
				? JSON.parse(this.$getSessionItem('activeMenu'))
				: { path: '/404' }
			// 设置返回状态
			this.setIsBack(true)
			this.$router.replace({
				path: '/redirect' + obj.path,
			})
		},
	},
	computed: {
		// 是否展示返回按钮
		isMain() {
			let queryArr = Object.keys(this.$route.query)
			if (
				this.$route.query.type == 'audit' ||
				this.$route.query.type == 'check' ||
				this.$route.query.type == 'close' ||
				this.$route.query.type == 'entrust'
			) {
				return false
			}
			if (
				this.$route.path == '/projectInfo' ||
				this.$route.path == '/entrust'
			) {
				return false
			}
			if (queryArr.length > 0) {
				return true
			}
			return false
		},

		//
		isShow() {
			let queryArr = Object.keys(this.$route.query)
			if (queryArr && queryArr.length > 0) {
				return false
			}
			return true
		},
	},
}
</script>

<style scoped lang="less">
@import url('./LayoutIndex.less');

.back-btn {
	height: 38px;
	position: absolute;
	right: 5px;
	top: 62px;
	cursor: pointer;
	img {
		height: 90%;
	}
}

.go-back-btn {
	height: 24px;
	position: absolute;
	right: 10px;
	top: 67px;
	line-height: 0;
	z-index: 1;
	// margin-top: 3px;
}
</style>

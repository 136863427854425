import {
    initRoutes,
    addActualRoutes,
} from 'utils/tools.js'
import { getUserAuth } from 'api/system/menu'

import router from '../../router'

const state = {
    activeTab: '', // 当前选中的 tab
    tabsList: [], // 选项卡数组
    activeRoutes: [],   // 动态返回的路由列表
    routeList: [],  // 路由提取到一级的数组集合
    roleBtns: [],   // 权限按钮组
    originalRoleBtns: [], // 原始权限按钮
}

const mutations = {
    SET_AVTIVE_ROUTES(state, routes) {
        state.activeRoutes = routes
    },
    SET_ROUTE_LIST(state, list) {
        state.routeList = list
    },
    SET_ROLE_BTNS(state, { path, rootState }) {
        state.roleBtns = []
        let _item = state.routeList.find(x => x.path === path)

        if (_item) {
            const btnArr = _item.buttons ? _item.buttons.map(y => y.alias) : []
            state.originalRoleBtns = btnArr // 为了查看对比

            if (rootState.user.isMaintenance) {
                // 运维角色，系统设置模块只能查看
                switch (path) {
                    case '/platform_user_management':
                        // 用户管理
                        state.roleBtns = ['platform_user_view']
                        break
                    case '/tenant_management':
                        // 单位管理
                        state.roleBtns = ['tenant_user_view']
                        break
                    case '/menu-management':
                    case '/limit_menu_range_management':
                    case '/role_plus_management':
                    case '/dict_management':
                    case '/DataRolePermissionsIndex':
                        state.roleBtns = []
                        break
                    case '/group_role_management':
                        // 项目组角色
                        state.roleBtns = ['group_role_view']
                        break
                    default:
                        state.roleBtns = btnArr
                        break
                }
            } else {
                state.roleBtns = btnArr
            }
        }
    }
}

const actions = {
    // 获取返回的菜单路由
    setMenuList: ({ commit }, token) => {
        let resArr = [
            /*            {
                            id: '10001001',
                            parentId: '1',
                            sname: '首页',
                            path: '/home',
                            componentPath: 'views/Home/HomeIndex',
                            componentName: 'HomeIndex',
                            icon: 'menu-home',
                            hidden: false,
                            children: null
                        },
                        {
                            id: '10001002',
                            parentId: '1',
                            sname: '我的审批',
                            path: '/approval',
                            componentPath: 'views/Approval/ApprovalIndex',
                            componentName: 'ApprovalIndex',
                            icon: 'menu-approval',
                            hidden: false,
                            children: null
                        },
                        {
                            id: '10001003',
                            parentId: '1',
                            sname: '项目管理',
                            path: '/projectManagement',
                            componentPath: 'views/ProjectManage/ProjectIndex',
                            componentName: 'ProjectIndex',
                            icon: 'menu-project',
                            hidden: false,
                            children: null
                        },
                        {
                            id: '10001004',
                            parentId: '1',
                            sname: '我的委托',
                            path: '/commission',
                            componentPath: 'views/Commission/CommissionIndex',
                            componentName: 'CommissionIndex',
                            icon: 'menu-commission',
                            hidden: false,
                            children: null
                        },
                        {
                            id: '10001005',
                            parentId: '1',
                            sname: '审计管理',
                            path: '/auditManagement',
                            componentPath: 'views/AuditManage/AuditIndex',
                            componentName: 'AuditManagement',
                            icon: 'menu-audit',
                            hidden: false,
                            children: null
                        },
                        {
                            id: '10001006',
                            parentId: '1',
                            sname: '审计任务',
                            path: '/auditTask',
                            componentPath: 'views/AuditTask/TaskIndex',
                            componentName: 'AuditTask',
                            icon: 'menu-task',
                            hidden: false,
                            children: null
                        },
                        {
                            id: '10001007',
                            parentId: '1',
                            sname: '单位维护',
                            path: '/unitMaintain',
                            componentPath: 'views/UnitMaintain/UnitIndex',
                            componentName: 'UnitMaintain',
                            icon: 'menu-company',
                            hidden: false,
                            children: null
                        },
                        // 公共
                        {
                            id: '10001008',
                            parentId: '1',
                            sname: '项目信息',
                            path: '/projectInfo',
                            componentPath: 'views/Common/ProjectInfo',
                            componentName: 'ProjectInfo',
                            icon: 'menu-company',
                            hidden: true,
                            children: null
                        },
                        {
                            id: '10001009',
                            parentId: '1',
                            sname: '项目信息查看',
                            path: '/projectInfoView',
                            componentPath: 'views/Common/ProjectInfoView',
                            componentName: 'ProjectInfoView',
                            icon: 'menu-company',
                            hidden: true,
                            children: null
                        },
                        {
                            id: '10001010',
                            parentId: '1',
                            sname: '项目信息',
                            path: '/projectInfoLook',
                            componentPath: 'views/Common/ProjectInfoLook',
                            componentName: 'ProjectInfoLook',
                            icon: 'menu-company',
                            hidden: true,
                            children: null
                        }, 
                        {
                            id: '10001011',
                            parentId: '1',
                            sname: '委托协审',
                            path: '/entrust',
                            componentPath: 'views/Commission/CommissionDetails',
                            componentName: 'CommissionDetails',
                            icon: 'menu-company',
                            hidden: true,
                            children: null
                        },*/
        ]

        // 动态路由数组
        let activeRoutes = addActualRoutes([], resArr)
        // activeRoutes.map(x => {
        //     router.addRoute('/main', x)
        // })

        // // 动态路由数组
        // if (resArr.length > 0) {
        //     let obj = router.options.routes.find(x => x.path === '/main')
        //     obj.redirect = resArr[0].path
        // }

        // console.log(router.getRoutes())
        // // 路由列表(平级)
        // let routeList = initRoutes(resArr, 'parentId')

        // commit('SET_AVTIVE_ROUTES', activeRoutes)
        // commit('SET_ROUTE_LIST', routeList)

        // return new Promise((resolve, reject) => {
        //     commit('SET_AVTIVE_ROUTES', activeRoutes)
        //     commit('SET_ROUTE_LIST', routeList)

        //     resolve()
        // })

        return new Promise((resolve, reject) => {
            getUserAuth({ token: token }).then(res => {   //, limitId : 64
                const { data } = res

                // if (data.length === 0) {
                //     reject('还账号无角色菜单，请配置后登录')
                // }
                // const data = [
                //     {
                //         id: '10002001',
                //         parentId: '1',
                //         sname: '系统管理',
                //         path: '/system-index',
                //         componentPath: 'views/system-management/index',
                //         componentName: 'SystemIndex',
                //         icon: 'component',
                //         hidden: false,
                //         children: [
                //             {
                //                 id: '10002001001',
                //                 parentId: '10002001',
                //                 sname: '菜单管理',
                //                 path: '/menu-management',
                //                 componentPath: 'views/system-management/menu/menu_management',
                //                 componentName: 'MenuManagement',
                //                 icon: 'menu-home',
                //                 hidden: false,
                //                 children: null
                //             },
                //         ]
                //     },
                // ]

                // 把返回的数组 处理成 项目需要的组件数据
                resArr.push(...data)
                let activeRoutes = addActualRoutes([], resArr)

                // 动态路由数组
                if (resArr.length > 0) {
                    let obj = router.options.routes.find(x => x.path === '/main')
                    obj.redirect = resArr[0].path
                }
                // resArr[0].redirect = resArr[0].path
                let routeList = initRoutes(resArr, 'parentId')

                commit('SET_AVTIVE_ROUTES', activeRoutes)
                commit('SET_ROUTE_LIST', routeList)

                resolve()
            }).catch(err => {
                reject(err)
            })
        })


        // return new Promise((resolve, reject) => {
        //     getUserAuth({ token: token }).then(res => {
        //         const { data } = res

        //         if (data.length === 0) {
        //             reject('该账号无角色菜单，请配置后登录')
        //         }

        //         // 把返回的数组 处理成 项目需要的组件数据
        //         resArr[0].children = addActualRoutes(resArr[0].children, data)
        //         resArr[0].redirect = resArr[0].children[0].path
        //         let routeList = initRoutes(resArr, 'parentId')
        //         // console.log(resArr)
        //         // console.log(routeList)

        //         commit('SET_AVTIVE_ROUTES', resArr)
        //         commit('SET_ROUTE_LIST', routeList)

        //         resolve()
        //     }).catch(err => {
        //         reject(err)
        //     })
        // })
    },

    // 设置当前路由的按钮权限组
    setRoleBtns: ({ commit, rootState }, path) => {
        commit('SET_ROLE_BTNS', { path, rootState })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
<!--
    描述：布局 --- 面包屑导航
    时间：2021-03-24
    Created by 王帅
-->
<template>
	<div class="app-breadCrumb">
		<span class="mr10">
			<svg-icon icon-class="yy-location"></svg-icon>
		</span>
		<el-breadcrumb separator-class="el-icon-arrow-right"
					   class="breadcrumb-container">
			<transition-group name="breadcrumb">
				<el-breadcrumb-item v-for="(item, index) in breadList"
									:key="item.path"
									:to="dealDis(item)">
					{{ item.meta.title }}
				</el-breadcrumb-item>
			</transition-group>
		</el-breadcrumb>
	</div>
</template>

<script>
import { $deepCopy } from 'utils/tools.js'
export default {
	name: 'app-BreadCrumb',
	data() {
		return {
			// breadList: [],
			oldList: [],
		}
	},
	created() {
		if (
			this.$route.path !== '/projectInfo' &&
			this.$route.path !== '/projectInfoLook' &&
			this.$route.path !== '/entrust' &&
			this.$route.path !== '/resource-page' &&
			this.$route.path !== '/noticeMore' &&
			this.$route.path !== '/AuditLook' &&
			this.$route.path !== '/AuditPlan' &&
			this.$route.path !== '/AuditOpinion' &&
			this.$route.path !== '/archiveInfo' &&
			this.$route.path !== '/auditMore' &&
			this.$route.path !== '/FeeDialog' &&
			this.$route.path !== '/evaluate-rules' &&
			this.$route.path !== '/evaluate-records' &&
			this.$route.path !== '/AuditPlan' &&
			this.$route.path !== '/reportAudit' &&
			this.$route.path !== '/BrowsingHistory'
		) {
			this.$getSessionItem('activeMenu')
				? this.$removeSessionItem('activeMenu')
				: ''
		}
	},
	methods: {
		// 处理是否可以点击当前面包屑
		dealDis(item) {
			if (
				item.path !== '/projectInfo' &&
				item.path !== '/entrust' &&
				item.path !== '/projectInfoLook' &&
				item.path !== '/AuditLook' &&
				item.path !== '/FeeDialog' &&
				item.path !== '/reportAudit' &&
				item.path !== '/archiveInfo' &&
				item.path !== '/AuditPlan' &&
				item.path !== '/AuditOpinion' &&
				item.path !== '/reportAudit'
			) {
				return '/redirect' + item.path
			}
			return ''
		},
	},
	computed: {
		// 面包屑数组
		breadList() {
			// only show routes with meta.title
			let _arr = this.$route.matched.concat()
			let copyArr = $deepCopy(_arr, 'Array')

			let _obj = this.$getSessionItem('activeMenu')
				? JSON.parse(this.$getSessionItem('activeMenu'))
				: ''
			if (_obj.resetName && _obj.resetName != '') {
				copyArr[0].meta.title = _obj.resetName
				_obj ? copyArr.unshift(_obj) : ''
				return copyArr.filter((x) => x.meta && x.meta.title)
			} else {
				_obj ? _arr.unshift(_obj) : ''
				return _arr.filter((x) => x.meta && x.meta.title)
			}
		},
	},
	watch: {
		//
		$route(to, from) {
			if (
				to.path !== '/projectInfo' &&
				to.path !== '/projectInfoView' &&
				to.path !== '/projectInfoLook' &&
				to.path !== '/AuditLook' &&
				to.path !== '/AuditPlan' &&
				to.path !== '/AuditOpinion' &&
				to.path !== '/archiveInfo' &&
				to.path !== '/entrust' &&
				to.path !== '/resource-page' &&
				to.path !== '/noticeMore' &&
				to.path !== '/auditMore' &&
				to.path !== '/FeeDialog' &&
				to.path !== '/evaluate-rules' &&
				to.path !== '/evaluate-records' &&
				to.path !== '/reportAudit' &&
				to.path !== '/AuditPlan' &&
				to.path !== '/BrowsingHistory'
			) {
				this.$getSessionItem('activeMenu')
					? this.$removeSessionItem('activeMenu')
					: ''
			}
		},
	},
}
</script>

<style scoped lang="less">
.app-breadCrumb {
	.goBack {
		height: 24px;
		position: absolute;
		right: 5px;
		top: 7px;
		padding: 3px 10px;
		color: #398e8d;
		background-color: #fff;
	}
}
</style>

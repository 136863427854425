import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js' // elementui
import '@/utils/directives.js'

import './assets/style/global.less';
import './assets/style/reset.less';
import './assets/style/layout.less';
import './assets/style/transtion.less';

import './icons' // icon
import './utils/nprogress' // nprogress进度条
import './utils/extends'	// js 浮点数计算

// 引入 Treeselect
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
Vue.component('Treeselect', Treeselect)

// 引入 wSplit
import wSplit from './components/split/split'
Vue.component('wSplit', wSplit)

import bmCustomTableVxe from 'bm-customtable-vxe' // 自定义表格
Vue.use(bmCustomTableVxe)

// import customForm from '@/components/customForm/index.js' // 自定义表单
// Vue.use(customForm)

import { getItem, setItem, removeItem } from './utils/storage.js'
Vue.prototype.$getSessionItem = getItem
Vue.prototype.$setSessionItem = setItem
Vue.prototype.$removeSessionItem = removeItem

// 删除二次确认函数
import { deleteConfirm } from './utils/tools'
Vue.prototype.$deleteConfirm = deleteConfirm

// 全局下载公共函数
import { downloadFile } from './utils/tools'
Vue.prototype.$downloadFile = downloadFile

// 全局注册组件
// 引入 upload
import myUpload from 'components/Upload/NewUpload'
Vue.component('myUpload', myUpload)

// 引入 fileName
import FileName from 'components/file/fileName'
Vue.component('FileName', FileName)

Vue.config.productionTip = false

// 设置全局预览 环境变量
Vue.prototype.$previewUrl = process.env.VUE_APP_PREVIEW_URL || ''
if (process.env.NODE_ENV == 'development') {
  Vue.prototype.$outUrl = window.location.origin.replace('8520', '8521')
} else {
  Vue.prototype.$outUrl = window.location.origin
}
// Vue.prototype.$outUrl = 'http://localhost:8521'
Vue.prototype.$uploadUrl = process.env.VUE_APP_UPLOAD_URL || ''
if (process.env.NODE_ENV == 'production') {
  let websocketUrl = ''
  let host = window.location.host
  websocketUrl = 'wss://' + host + '/websocket/ws/userId='
  Vue.prototype.$websocketUrl = websocketUrl
} else {
  Vue.prototype.$websocketUrl = process.env.VUE_APP_WEBSOCKET_URL || ''
}
// Vue.prototype.$websocketUrl = 'ws://172.16.0.14:9000/ws/userId='

import myWebsocket from 'utils/websocket.js'
Vue.prototype.mySocket = new myWebsocket()

// 加载 vxe-table
import XEUtils from 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'

Vue.use(VXETable)
Vue.prototype.$utils = XEUtils

// 全局inputFilter
import inputFilter from 'utils/input-filter/index.js'

Vue.use(inputFilter)

// 加载富文本
import VueQuillEditor from 'vue-quill-editor'

// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor, /* { default global options } */)
console.log(process.env)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

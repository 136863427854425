import { loginIn, getInfo, logOut, mobileLogin, checkedMaintenanceRole } from 'api/user/userIndex'
import { getItem, setItem, removeItem } from 'utils/storage'
import { getPublicIP, browserRedirect, ipcalfMethod } from 'utils/tools'
import Vue from 'vue'

const state = {
    token: getItem('token'),
    roles: [],
    userInfo: {},
    tenantId: '',
    isMaintenance: false, // 是否是运维角色
}

const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_ROLES: (state, roles) => {
        state.roles = roles
    },
    SET_USER_INFO: (state, info) => {
        state.userInfo = info
    },
    SET_TENANTID: (state, info) => {
        state.tenantId = info
    },
    SET_ISMAINTENANCE: (state, payload) => {
        state.isMaintenance = payload
    }
}

const actions = {
    // user login
    loginIn({ commit }, userInfo) {
        const { username, password } = userInfo
        return new Promise((resolve, reject) => {
            loginIn({
                username: username.trim(),  // 去除首尾空格
                password: password
            }).then(res => {
                // console.log(res)

                // 保存 token
                commit('SET_TOKEN', res.data.token)
                setItem('token', res.data.token)

                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },

    // 手机号登录
    mobileLogin({ commit }, userInfo) {
        const { phone, verifyCode } = userInfo
        return new Promise((resolve, reject) => {
            mobileLogin({
                phone: phone.trim(),  // 去除首尾空格
                verifyCode: verifyCode
            }).then(res => {
                // console.log(res)
                if (res.operationCode === 1) {
                    // 保存 token
                    commit('SET_TOKEN', res.data.token)
                    setItem('token', res.data.token)
                }

                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },

    // 获取用户信息
    getUserInfo({ dispatch, commit, state }, info) {
        return new Promise((resolve, reject) => {
            getInfo({ token: state.token }).then(res => {
                const { data } = res

                if (!data) {
                    reject('验证失败，请重新登录')
                }

                commit('SET_USER_INFO', data)
                // commit('SET_TENANTID', data.tenantId)
                // setItem('tenantId', res.data.tenantId)

                // 判断运维角色
                dispatch('checkedMaintenanceRole')

                resolve(data)
            }).catch(err => {
                console.log(err)
                reject(err)
            })
        })
    },

    // 判断是否是运维角色
    checkedMaintenanceRole({ dispatch, commit }) {
        return new Promise((resolve, reject) => {
            checkedMaintenanceRole().then(res => {
                if (res.data) {
                    commit('SET_ISMAINTENANCE', res.data.isChecked == 1) // 0不是 1是
                    if (res.data.isChecked == 1) {
                        console.log('运维角色！！')
                        // 获取内外网ip，操作系统
                        getPublicIP()
                        ipcalfMethod()
                        setItem('os', browserRedirect())
                    }
                    resolve(res.data.isChecked)
                }
            }).catch(err => {
                reject(err)
            })
        })
    },

    // 退出登录
    logOut({ commit, state }) {
        // 关闭websocket
        Vue.prototype.mySocket.ws ? Vue.prototype.mySocket.sendMessage(Vue.prototype.mySocket.ws, state.userInfo.id) : ''

        commit('SET_TOKEN', '')
        commit('SET_ROLES', [])
        commit('SET_USER_INFO', {})
        removeItem('token')
        removeItem('tenantId')
        removeItem('limitId')
        removeItem('companyName')

    },

    // 保存tenantId
    saveTenantId({
        commit
    }, info) {
        commit("SET_TENANTID", info);
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
// vue.config.js
const path = require('path');//引入path模块

const defaultSettings = require('./src/utils/settings')

const name = defaultSettings.title

//path.join(__dirname)设置绝对路径
function resolve(dir) {
	return path.join(__dirname, dir)
}

module.exports = {
	// 基础路径
	// -----------默认情况下，Vue CLI 会假设你的应用是被部署在一个域名的根路径上，例如 https://www.my-app.com/。
	// -----------如果应用被部署在一个子路径上，你就需要用这个选项指定这个子路径。例如，如果你的应用被部署在 https://www.my-app.com/my-app/，则设置 publicPath 为 /my-app/。
	publicPath:
		process.env.NODE_ENV !== "development" ? "/DxAuditAdmin/" : "/DxAuditAdmin/",

	// 打包目录
	// -----------注意目标目录在构建之前会被清除 (构建时传入 --no-clean 可关闭该行为)。
	// -----------默认值'dist'
	outputDir: "DxAuditAdmin",

	// 放置生成的静态资源 (js、css、img、fonts) 的目录(相对于outputDir目录)。
	// -----------默认值''
	assetsDir: "./",

	//指定生成的 index.html 的输出路径 (相对于 outputDir)。也可以是一个绝对路径。
	// 默认值'index.html'
	indexPath: "index.html",

	// 默认情况下，生成的静态资源在它们的文件名中包含了 hash 以便更好的控制缓存。
	filenameHashing: false,

	// 是否在开发环境下通过 eslint-loader 在每次保存时 lint 代码。这个值会在 @vue/cli-plugin-eslint 被安装之后生效。
	// lintOnSave: process.env.NODE_ENV !== "production",
	lintOnSave: false,

	//是否使用包含运行时编译器的 Vue 构建版本。设置为 true 后你就可以在 Vue 组件中使用 template 选项了，但是这会让你的应用额外增加 10kb 左右。
	runtimeCompiler: false,

	// 生产环境是否生成 sourceMap 文件
	productionSourceMap: true,

	// css
	css: {
		sourceMap: true
	},

	// 所有 webpack-dev-server 的选项都支持。
	configureWebpack: {
		// provide the app's title in webpack's name field, so that
		// it can be accessed in index.html to inject the correct title.
		name: name,
		// resolve: {
		// 	alias: {
		// 		'@': resolve('src')
		// 	}
		// }
	},
	chainWebpack: (config) => {
		//set第一个参数：设置的别名，第二个参数：设置的路径
		config.resolve.alias
			.set('@', resolve('./src'))
			.set('components', resolve('./src/components'))
			.set('assets', resolve('./src/assets'))
			.set('views', resolve('./src/views'))
			.set('utils', resolve('./src/utils'))
			.set('store', resolve('./src/store'))
			.set('api', resolve('./src/api'))

		// set svg-sprite-loader
		config.module
			.rule('svg')
			.exclude.add(resolve('src/icons'))
			.end()
		config.module
			.rule('icons')
			.test(/\.svg$/)
			.include.add(resolve('src/icons'))
			.end()
			.use('svg-sprite-loader')
			.loader('svg-sprite-loader')
			.options({
				symbolId: 'icon-[name]'
			})
			.end()

		// config.module.rule('md')
		// 	.test(/\.md/)
		// 	.use('vue-loader')
		// 	.loader('vue-loader')
		// 	.end()
		// 	.use('vue-markdown-loader')
		// 	.loader('vue-markdown-loader/lib/markdown-compiler')
		// 	.options({
		// 		raw: true
		// 	})
		// 	.end()
	},
	devServer: {
		host: "0.0.0.0",
		port: 8520, // 端口号
		https: false,
		open: false, //配置自动启动浏览器

		// 配置多个代理
		proxy: {
			"/uploadApi": {
				target: "http://112.31.81.178:4000/file", // 本地模拟数据服务器
				changeOrigin: true,
				pathRewrite: {
					"^/uploadApi": "" // 去掉接口地址中的api字符串
				}
			},
			// 预发环境
			"/pre": {
				target: "http://112.31.81.178:3000/bm-audit-yufa/", // 本地模拟数据服务器
				changeOrigin: true,
				pathRewrite: {
					"^/pre": "" // 去掉接口地址中的api字符串
				}
			},
			// 测试环境
			"/test": {
				target: "https://c.nenggongshe.com/dxAudit", // 本地模拟数据服务器
				changeOrigin: true,
				pathRewrite: {
					"^/test": "" // 去掉接口地址中的api字符串
				}
			},
		},
		before: (app, server) => { }
	}
};

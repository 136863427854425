// 所有公共码表管理
import {
    getAllByKey
} from "api/dict/dict";

const state = {
    projectDict: {}, // 码表对象
    dwlx: [], //单位类型
    dwzt: [], //单位主体
    xmzjqj: [], //项目造价区间
    bdlx: [], //标段(包) 类型
    splx: [], //审批类型
    xmlx: [], //项目类型
    sjrwlx: [], //审计任务类型
    sjzllx: [], //资料类型
}

const mutations = {
    // 设置码表
    SET_DICTS: (state, obj) => {
        state.projectDict = Object.assign({}, obj)
    },
    // 单位类型
    SET_DWLX: (state, arr) => {
        state.dwlx = arr
    },
    // 单位主体
    SET_DWZT: (state, arr) => {
        state.dwzt = arr
    },
    // 项目造价区间
    SET_XMZJQJ: (state, arr) => {
        state.xmzjqj = arr
    },
    // 标段(包) 类型
    SET_BDLX: (state, arr) => {
        state.bdlx = arr
    },
    // 审批类型
    SET_SPLX: (state, arr) => {
        state.splx = arr
    },
    // 项目类型
    SET_XMLX: (state, arr) => {
        state.xmlx = arr
    },
    // 审计任务类型
    SET_SJRWLX: (state, arr) => {
        state.sjrwlx = arr
    },
    // 资料类型
    SET_ZLLX: (state, arr) => {
        state.sjzllx = arr
    },
}

const actions = {
    // 获取码表
    getAllDict({
        commit,
        dispatch
    }) {
        let fun1 = dispatch('getCommonDict')
        return Promise.all([fun1])
            .then(res => { })
            .catch(err => { })
    },

    // 获取所有公共码表
    getCommonDict({
        commit
    }) {
        return new Promise((resolve, reject) => {
            getAllByKey({
                identityNo: 'ngs-audit,ngs-manage'
            })
                .then(res => {
                    // 保存 码表
                    commit('SET_DICTS', res.data)
                    commit('SET_DWLX', res.data.dwlx) //单位类型
                    commit('SET_DWZT', res.data.dwzt) //单位主体
                    commit('SET_XMZJQJ', res.data.xmzjqj) //项目造价区间
                    commit('SET_BDLX', res.data.bdlx) //标段(包) 类型
                    commit('SET_SPLX', res.data.splx) //审批类型
                    commit('SET_XMLX', res.data.xmlx) //项目类型
                    commit('SET_SJRWLX', res.data.sjrwlx) //审计任务类型
                    commit('SET_ZLLX', res.data.sjzllx) //资料类型

                    resolve()
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
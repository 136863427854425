/****
 *  websocket 类
 *  author: ws
 *  
 * ***/ 

export default class myWebsocket {
    /**
     * 定义 websocket 对象
     * @param {String} url  链接的地址 
     * @param {String} userId   用户ID
     * **/ 
    constructor() {
        this.ws = null
        this.heart = null
    }

    /**
     * 创建websocket 连接
     * **/
    createSocket(url, userId) {
        let that = this

        // 关闭
        that.ws && that.ws.close()

        console.log('建立websocket连接')
        that.ws = new WebSocket(url + userId)
        that.ws.onopen = function (e) {
            that.socketOpen(e)
            that.sendHeart(that.ws)
        }
        that.ws.onmessage = that.socketMessage
        that.ws.onerror = that.socketError
        that.ws.onclose = function(e) {
            // that.socketClose(e)
            clearInterval(that.heart)
        }
        // if (!that.ws) {
        //     console.log('建立websocket连接')
        //     that.ws = new WebSocket(url + userId)
        //     that.ws.onopen = function(e) {
        //         that.socketOpen(e)
        //         that.sendHeart(that.ws)
        //     }
        //     that.ws.onmessage = that.socketMessage
        //     that.ws.onerror = that.socketError
        //     that.ws.onclose = that.socketClose
        // } else {
        //     console.log('websocket已连接')
        // }
    }

    /**
     * 打开websocket 
     * 发送心跳
     * **/
    socketOpen(e) {
        console.log('open', e)
    }


    /**
     * websocket 发送信息
     * **/
    socketMessage(e) {
        // console.log('message', e)
    }


    /**
     * websocket 连接失败 重连
     * **/
    socketError(e) {
        let that = this
        that.ws.close()
        clearInterval(this.heart)
        console.log('连接失败重连中')
        if (that.ws.readyState !== 3) {
            that.ws = null
            that.createSocket()
        }
    }


    /**
     * websocket 关闭
     * **/
    socketClose(e) {
        console.log('close', e)
        let that = this
        that.ws.close()
        // clearInterval(this.heart)

        let quit = window.sessionStorage.getItem('quit')
        if(!quit){
            let origin = window.location.origin;
            let pathname = window.location.pathname;
            window.location.href = `${origin}${pathname}#/login`;
        }
        window.sessionStorage.removeItem("quit");
    }


    /**
     * 发送心跳函数
     * **/
    sendHeart(Socket, time = 30000, ping = '1') {
        clearInterval(this.heart)
        
        // 发送心跳
        Socket.send(ping)
        this.heart = setInterval(() => {
            Socket.send(ping)
        }, time)
    }


    /**
     * 发送退出信息
     * **/
    sendMessage(Socket, userId) {
        let params = {
            userId: userId,
            action: "logout"
        }
        
        // 发送心跳
        Socket.send(JSON.stringify(params))
    }
}
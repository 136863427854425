import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
// 解决报错
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
	if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
	return originalPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
	if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
	return originalReplace.call(this, location).catch(err => err)
}

/* layout */
import Layout from '../layout/LayoutIndex'

const routes = [{
		path: '/',
		name: '/',
		// component: Layout,
		redirect: '/login',
	},
	{
		path: '/login',
		name: 'login',
		meta: {
			title: '登录',
		},
		// component: () => import('../views/Login/Login.vue')
		component: () => import('../views/Login/index.vue')
	},
	{
		path: '/Select',
		name: 'SelectType',
		meta: {
			title: '区域板',
		},
		// component: () => import('../views/Login/Login.vue')
		component: () => import('../views/Home/SelectArea.vue')
	},
	{
		path: '/main',
		name: 'Main',
		meta: {
			title: '主界面',
		},
		component: Layout,
		// redirect: '/system-management',
		redirect: '/home',
	},
	{
		path: '/formMarker',
		name: 'formMarker',
		meta: {
			title: 'formMarker',
		},
		component: () => import('../views/formMarker/formMarker.vue'),
	},
	{
		path: '/choseCompany',
		name: 'choseCompany',
		meta: {
			title: 'choseCompany',
		},
		component: () => import('../views/ChoseCompany/index.vue'),
	},
	{
		path: '/choseMain',
		name: 'choseMain',
		meta: {
			title: 'choseMain',
		},
		component: () => import('../views/ChoseMain/index.vue'),
	},
	{
		path: '/404',
		name: '404',
		meta: {
			title: '404',
		},
		component: () => import('../views/ErrorPage/404.vue'),
	},
	{
		path: '/redirect',
		component: () => import('../views/Redirect/RedirectIndex'),
		hidden: true,
		children: [{
			path: '/redirect/:path*',
			component: () => import('../views/Redirect/RedirectIndex')
		}]
	},
	// 更多通知页面
	{
		path: '/noticeMore',
		name: 'noticeMore',
		meta: {
			title: '更多通知',
		},
		component: () => import('../views/Home/NoticeMore.vue'),
	},
	// 更多审计动态页面
	{
		path: '/auditMore',
		name: 'AuditMore',
		meta: {
			title: '审计动态',
		},
		component: () => import('../views/Home/AuditMore.vue'),
	},
	// 资源库 Resource-frame
	{
		path: '/resource-page',
		name: 'Resource-frame',
		meta: {
			title: '资源',
		},
		component: () => import('../views/Resource/ResourceFrame.vue'),
	},
	// 审核归档页面
	{
		path: '/archiveInfo',
		name: 'ArchiveInfo',
		meta: {
			title: '归档信息',
		},
		component: () => import('../views/Approval/ApprovalIndex.vue'),
	},
	// 审计报告页面
	{
		path: '/reportAudit',
		name: 'ReportAudit',
		meta: {
			title: '审计报告',
		},
		component: () => import('../views/Common/ReportAudit.vue'),
	},
	// 审计方案页面
	{
		path: '/AuditPlan',
		name: 'AuditPlan',
		meta: {
			title: '审计方案',
		},
		component: () => import('../views/Common/AuditPlan.vue'),
	},
	// 审计方案页面
	{
		path: '/AuditOpinion',
		name: 'AuditOpinion',
		meta: {
			title: '审计意见单',
		},
		component: () => import('../views/Common/AuditOpinion.vue'),
	},
	// 个人资料页面
	{
		path: '/userInfo',
		name: 'UserInfo',
		meta: {
			title: '个人资料',
		},
		component: () => import('../views/UserInfo/UserIndex.vue'),
	},
	// 智慧地图
	{
		path: '/projectMapOnly',
		name: 'ProjectMapOnly',
		meta: {
			title: '智慧地图',
		},
		component: () => import('../views/ProjectMap/ProjectMapIndexOnly.vue'),
	},
	// 运维人员管理
	{
		path: '/yw',
		name: 'OperationManagement',
		meta: {
			title: '运维操作',
		},
		component: () => import('../views/Common/OperationManagement.vue'),
	},
    // 运维人员操作日志
	{
		path: '/ywOperationLog',
		name: 'ywOperationLog',
		meta: {
			title: '操作日志',
		},
		component: () => import('../views/Common/ywOperationLog.vue'),
	},
]

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes
})

export default router
/**
* 描述：评价系统 --- 评分项 接口管理
* 录入人： 王帅
*/
import request from '../request.js'

/**
* 描述：获取评分字典 --- 评价系统独立后台
* 参数：{}
*/
export const getEvaluateDict = function (data) {
    return request({
        url: 'markRule/getDictsBySysCode',
        method: 'post',
        data
    })
}

/**
* 描述：查询评分项列表数据
* 接口管理员：俞祥东
* 参数： 
{
    size:10,          // 当前每页条数
    current:1,        // 当前页码
    markObj:'',       // 评价对象
    startTime:'',     // 创建时间 -- 开始
    endTime:''        // 创建时间 -- 结束
}
*/
export const getListData = function (data) {
    return request({
        url: 'markItem/selOperateMarkItemPageList',
        method: 'post',
        requestConfig: {
            hideLoading: true
        },
        data
    })
}

/**
* 描述：评分项新增或修改
* 接口管理员：俞祥东
* 参数：｛Object｝
{
    markObj: "1007001",                 // 评价对象
    itemType: "1367467053341540357",    // 类别
    itemTypeName: "xxx",                // 新增类别字段
    sname: "俞祥东测试数据2",            // 名称
    content: "评审过程人员的准备情况",    // 内容
    standard: "参与配合程度",            // 评分标准
    createUserid: "112233445566",       // 创建人ID
    createUser: "周杰伦",               // 创建人姓名
}
*/
export const saveOrUpdateItem = function (data) {
    return request({
        url: 'markItem/addOrEditOperateMarkItem',
        method: 'post',
        requestConfig: {
            hideLoading: true
        },
        data
    })
}

/**
* 描述：评分项删除
* 接口管理员：俞祥东
* 参数： id｛Long｝
{
   id: xxx
}
*/
export const deleteItem = function (data) {
    return request({
        url: 'markItem/delOperateMarkItem',
        method: 'post',
        requestConfig: {
            hideLoading: true
        },
        data
    })
}

/**
* 描述：评价对象与类别的联动
* 接口管理员：俞祥东
* 参数：markObj｛String｝
{
   markObj: ''
}
*/
export const changeMark = function (data) {
    return request({
        url: 'itemType/getItemTypeByMarkObj',
        method: 'post',
        requestConfig: {
            hideLoading: true
        },
        data
    })
}
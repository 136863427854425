import Vue from 'vue'

const addListener = function(el, type, fn) {
    el.addEventListener(type, fn, false)
}

const spaceFilter = function(el) { // 去除空格
    addListener(el, 'keyup', () => {
        el.value = el.value.replace(/\s+/, '')
    })
}

const intFilter = function(el) { // 只保留数字
    addListener(el, 'keyup', () => {
        el.value = el.value.replace(/\D/g, '')
    })
}

const priceFilter = function(el) { // 只保留价格形式
    addListener(el, 'keyup', () => {
        el.value = el.value.replace(/[^\d.]*/g, '')
        if (isNaN(el.value)) {
            el.value = ''
        }
    })
}

const specialFilter = function(el) { // 去除特殊符号
    addListener(el, 'keyup', () => {
        el.value = el.value.replace(/[\@\#\$\%\^\&\*\(\)\{\}\:\"\L\<\>\?\[\]\~]/)
    })
}

const punctuationFilter = function(el) { // 去除标点
    addListener(el, 'keyup', () => {
        el.value = el.value.replace(/[\ |\~|\`|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\-|\_|\+|\=|\||\\|\[|\]|\{|\}|\;|\:|\"|\'|\,|\<|\.|\>|\/|\?]/g, "")
    })
}

const chineseFilter = function(el) { // 去除中文
    addListener(el, 'keyup', () => {
        el.value = el.value.replace(/[\u4e00-\u9fa5]/g, '')
    })
}

const keepChinese = function(el) { // 保留中文
    addListener(el, 'keyup', () => {
        el.value = el.value.replace(/[^\u4e00-\u9fa5]/gi, '')
    })
}

const englishFilter = function(el) { // 去除英文
    addListener(el, 'keyup', () => {
        el.value = el.value.replace(/[a-zA-Z]/g, '')
    })
}

const keepEnglish = function(el) { // 保留英文
    addListener(el, 'keyup', () => {
        el.value = el.value.replace(/[^a-zA-Z]/gi, '')
    })
}

// const phoneFilter = function(el) {
//     addListener(el, 'blur', () => {
//         if (!el.value) {
//             return
//         }
//         const phoneReg = new RegExp('^(13|14|15|16|17|18|19)[0-9]{9}$')
//         if (!phoneReg.test(el.value)) {
//             Message({
//                 message: '手机号输入错误',
//                 type: 'error',
//                 showClose: true,
//                 duration: 1000
//             })
//             el.value = ''
//         }
//     })
// }

// const urlFilter = function(el) {
//     addListener(el, 'blur', () => {
//         if (!el.value) {
//             return
//         }
//         const urlReg = /(^#)|(^http(s*):\/\/[^\s]+\.[^\s]+)/
//         if (!urlReg.test(el.value)) {
//             Message({
//                 message: 'url输入错误',
//                 type: 'error',
//                 showClose: true,
//                 duration: 1000
//             })
//             el.value = ''
//         }
//     })
// }

// const limitNum = function(el) {
//     addListener(el, 'change', () => {
//         if (!el.value) {
//             return
//         }
//         el.value = el.value.replace(/\D/g, '')
//         if (el.value > 100) {
//             Message({
//                 message: '输入值不能超过100',
//                 type: 'error',
//                 showClose: true,
//                 duration: 1000
//             })
//             el.value = 100
//         } 
//     })
// }

// const IDCardFilter = function(el) {
//     addListener(el, 'blur', () => {
//         if (!el.value) {
//             return
//         }
//         const reg = /(^\d{15}$)|bai(^\d{18}$)|(^\d{17}(\d|X|x)$)/
//         if (!reg.test(el.value)) {
//             Message({
//                 message: '身份证号码输入错误',
//                 type: 'error',
//                 showClose: true,
//                 duration: 1000
//             })
//             el.value = ''
//         }
//     })
// }

// const emailFilter = function(el) {
//     addListener(el, 'blur', () => {
//         if (!el.value) {
//             return
//         }
//         const reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/
//         if (!reg.test(el.value)) {
//             Message({
//                 message: '邮箱格式错误',
//                 type: 'error',
//                 showClose: true,
//                 duration: 1000
//             })
//             el.value = ''
//         }
//     })
// }

const basicFilter = function(el) { // 空格 标点 特殊符号全去
    addListener(el, 'keyup', () => {
        // 去除空格 标点
        el.value = 
        el.value.replace(/[\ |\~|\`|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\-|\_|\+|\=|\||\\|\[|\]|\{|\}|\;|\:|\"|\'|\,|\<|\.|\>|\/|\?]/g, '')
        el.value = el.value.replace(/[\'\"\\\/\b\f\n\r\t]/g, '') // 去除转义字符
        el.value = el.value.replace(/[\@\#\$\%\^\&\*\(\)\{\}\:\"\L\<\>\?\[\]\~]/) // 去除特殊符号
    })
}

export default {
    bind(el, binding) {
        if (el.tagName.toLowerCase() !== 'input') {
            el = el.getElementsByTagName('input')[0] || el.getElementsByTagName('textarea')[0]
        }
        spaceFilter(el)
        switch (binding.arg) {
            case 'int': // 整数
                intFilter(el)
                break
            case 'price': // 价格
                priceFilter(el)
                break
            case 'special': // 去特殊字符
                specialFilter(el)
                break
            case 'space': // 去空格
                spaceFilter(el)
                break  
            case 'punctuation': // 去标点符号
                punctuationFilter(el)
                break
            case 'chinese': // 去中文
                chineseFilter(el)
                break
            case 'keepChinese': // 中文
                keepChinese(el)
                break    
            case 'english': // 去英文
                englishFilter(el)
                break
            case 'keepEnglish': // 英文
                keepEnglish(el)
                break                  
            // case 'phone': // 手机号
            //     phoneFilter(el)
            //     break
            // case 'url': // 路径
            //     urlFilter(el)
            //     break
            // case 'limitNum': // 需要被警告的值
            //     limitNum(el)
            //     break
            // case 'IDCard':  // 身份证验证
            //     IDCardFilter(el)
            //     break
            // case 'email': // 邮箱
            //     emailFilter(el)
            //     break
            case 'basic': // 标点符号、空格、特殊字符
                basicFilter(el)
                break
            default:
                break
        }
    }
}
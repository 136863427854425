import request from '../request.js'

// 获取菜单权限 --- 张卫华
const getUserAuth = function (data) {
    return request({
        url: 'sysMenu/getUserAuth',
        method: 'post',
        data
    })
}

// 获取菜单信息
const selectAll = function (data) {
    return request({
        url: 'sysMenu/selectAll',
        method: 'post',
        data
    })
}

const selectTree = function (data) {
    return request({
        url: 'sysMenu/selectTree',
        method: 'post',
        data
    })
}

// 主体菜单主体单位信息 --- 郑志会
const selectLimitTree = function (data) {
    return request({
        url: 'sysMenu/selectMenuTree',
        method: 'post',
        data
    })
}

const saveOrUpdate = function (data) {
    return request({
        url: 'sysMenu/saveOrUpdate',
        method: 'post',
        data
    })
}

const getInfoById = function (data) {
    return request({
        url: 'sysMenu/getInfoById',
        method: 'post',
        data
    })
}

const deleteById = function (data) {
    return request({
        url: 'sysMenu/deleteById',
        method: 'post',
        data
    })
}

const getMenuInterfaces = function (data) {
    return request({
        url: 'sysInterface/getMenuInterfaces',
        method: 'post',
        data
    })
}

//zwh-菜单接口
const deleteInfo = function (data) {  //删除
    return request({
        url: 'sysMenuInterface/deleteInfo',
        method: 'post',
        data
    })
}

const saveOnly = function (data) {  //保存
    return request({
        url: 'sysMenuInterface/saveOnly',
        method: 'post',
        data
    })
}

export {
    getUserAuth,
    selectAll,
    selectTree,
    saveOrUpdate,
    getInfoById,
    deleteById,
    getMenuInterfaces,
    deleteInfo,
    saveOnly,
    selectLimitTree
}

// 所有公共码表管理
import { getEvaluateDict } from 'api/evaluateManage/EvaluateItem.js'

const state = {
    pjdx: [],       // 评价对象
    pjrjs: [],      // 评价人角色
    syyw: [],       // 适用业务
}

const mutations = {
    // 评价对象
    SET_PJDX: (state, arr) => {
        state.pjdx = arr
    },
    // 评价人角色
    SET_PJRJS: (state, arr) => {
        state.pjrjs = arr
    },
    // 适用业务
    SET_SYYW: (state, arr) => {
        state.syyw = arr
    },
}

const actions = {
    // 获取码表
    getEvaluateDict({ commit, dispatch }) {
        let fun1 = dispatch('getCommonDict')
        // let fun2 = dispatch('getOwnerTradeList')
        return Promise.all([fun1])
            .then(res => { })
            .catch(err => { })
    },
    // 获取所有公共码表
    getCommonDict({ commit }) {
        return new Promise((resolve, reject) => {
            getEvaluateDict()
                .then(res => {
                    // // 保存 码表
                    commit('SET_PJDX', res.data.pjdx) // 评价对象
                    commit('SET_PJRJS', res.data.pjjs) // 评价人角色
                    commit('SET_SYYW', res.data.syyw) // 适用业务

                    resolve()
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}

<template>
	<div id="app">
		<div class="full-loading"
			 v-loading.fullscreen.lock="fullScreenLoading"
			 element-loading-text="页面加载中，请稍候...."
			 element-loading-spinner="el-icon-loading"></div>
		<transition name="fade"
					mode="out-in">
			<router-view v-if="whiteList"></router-view>
			<Layout v-else></Layout>
		</transition>
	</div>
</template>

<script>
import Layout from './layout/LayoutIndex'
import { mapActions, mapState } from 'vuex'

export default {
	name: 'app',
	components: {
		Layout,
	},
	created() {
		// 初始页面加载状态
		this.setFullLoading(true)

		setTimeout(() => {
			this.setFullLoading(false)
		}, 2000)

		if (this.token && this.token !== undefined) {
			// 加载码表
			this.getAllDict()
		}
	},
	mounted() {
		// 存储浏览器窗口变化
		window.onresize = () => {
			return (() => {
				let obj = {}
				obj.width = document.documentElement.clientWidth
				obj.height = document.documentElement.clientHeight
				this.setClientSize(obj)
			})()
		}
	},
	methods: {
		...mapActions('app', ['setClientSize', 'setFullLoading']),
		...mapActions('menu', ['setMenuList']),
		...mapActions('dict', ['getAllDict']),
	},
	computed: {
		...mapState('app', {
			fullScreenLoading: (state) => state.fullScreenLoading,
		}),

		...mapState('user', {
			userInfo: state => state.userInfo,
			token: (state) => state.token,
		}),

		// 路由白名单
		whiteList() {
			let arr = ['/', '/login', '/404', '/choseMain', '/choseCompany', '/Select', '/projectMapOnly']
			return arr.includes(this.routePath)
		},

		// 当前路由path
		routePath() {
			return this.$route.path
		},
	},
	watch: {
		// token有值时加载  初次登入时
		token(val) {
			if (val) {
				// 加载码表
				this.getAllDict()
			}
		},

		// websocket
        "userInfo.id": {
            handler: function (val) {
                if (this.token) {
                    if (val) {
                        let userId = val || "";
                        this.mySocket.createSocket(this.$websocketUrl, userId)
                    }
                }
            },
            deep: true,
        },
	},
}
</script>

<style></style>

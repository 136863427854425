// ---- 数字的加减运算，解决 js 浮点数的计算bug

// 加法
Number.prototype.add = function (arg) {
    let r1, r2, m, c
    arg = Number.isNaN(arg) ? 0 : arg
    try {
        r1 = this.toString().split('.')[1].length
    } catch (e) {
        r1 = 0
    }
    try {
        r2 = arg.toString().split('.')[1].length
    } catch (e) {
        r2 = 0
    }
    c = Math.abs(r1 - r2)
    m = Math.pow(10, Math.max(r1, r2))
    if (c > 0) {
        let cm = Math.pow(10, c)
        if (r1 > r2) {
            r1 = Number(this.toString().replace('.', ''))
            arg = Number(arg.toString().replace('.', '')) * cm
        } else {
            r1 = Number(this.toString().replace('.', '')) * cm
            arg = Number(arg.toString().replace('.', ''))
        }
    } else {
        r1 = Number(this.toString().replace('.', ''))
        arg = Number(arg.toString().replace('.', ''))
    }
    return (r1 + arg) / m
}

// 减法
Number.prototype.sub = function (arg) {
    return this.add(-arg)
}

// 乘法
Number.prototype.mul = function (arg) {
    if (!arg || !this || Number.isNaN(arg) || Number.isNaN(this)) {
        return 0
    }
    // console.log(this)
    // console.log(arg)
    let m = 0
    let s1 = this.toString()
    let s2 = arg.toString()

    try {
        m += s1.split('.')[1].length
    } catch (e) {
    }

    try {
        m += s2.split('.')[1].length
    } catch (e) {
    }
    return Number(s1.replace('.', '')) * Number(s2.replace('.', '')) / Math.pow(10, m)
}

// 在数组指定位置插入数组
Array.prototype.insert = function (index, array) {
    let _front = this.slice(0, index + 1)
    let _back = this.slice(index + 1, this.length)

    return _front.concat(array).concat(_back)
}

// 数组求和
Array.prototype.sum = function (callback) {
    let S, k

    if (this === null) {
        throw new TypeError(' this is null or not defined')
    }

    // 1. 将O赋值为调用map方法的数组.
    let O = Object(this)
    // 2.将len赋值为数组O的长度.
    let len = O.length >>> 0
    // 3.如果callback不是函数,则抛出TypeError异常.
    if (Object.prototype.toString.call(callback) !== '[object Function]' && Object.prototype.toString.call(callback) !== '[object Undefined]') {
        throw new TypeError(callback + ' is not a function and is not null')
    }
    // 4. 将S赋值为0
    S = 0
    // 5. 将k赋值为0
    k = 0
    // 6. 当 k < len 时,执行循环.
    while (k < len) {
        let kValue, mappedValue
        // 遍历O,k为原数组索引
        if (k in O) {
            // kValue为索引k对应的值.
            kValue = O[k]
            // 执行callback
            mappedValue = callback ? callback(kValue) : kValue
            // 返回值与S相加.
            S = S.add(mappedValue)
        }
        // k自增1
        k++
    }
    // 8. 返回合计值S
    return S
}

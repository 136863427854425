import Cookies from 'js-cookie'

const TokenKey = 'token'

export function getToken() {
    return Cookies.get(TokenKey)
}

export function setToken(token) {
    return Cookies.set(TokenKey, token)
}

export function removeToken() {
    return Cookies.remove(TokenKey)
}

// sessionStorage
export function getItem(item) {
    // return localStorage.getItem(item)
    return sessionStorage.getItem(item)
}

export function setItem(key, item) {
    // return localStorage.setItem(key, item)
    return sessionStorage.setItem(key, item)
}

export function removeItem(item) {
    // return localStorage.removeItem(item)
    return sessionStorage.removeItem(item)
}
/**
* 描述：人员、用户信息接口 汇总
* 录入人： 王帅
*/

// 用户登录、获取用户信息接口管理
import request from '../request.js'

/**
* 描述：登录接口
* 参数：
{
    username: '',   // --- 用户名
    password: ''    // --- 密码
}
*/
export const loginIn = function (data) {
    return request({
        url: 'sysUser/login',
        method: 'post',
        data
    })
}

/**
* 描述：手机号登录 --- 郑志会
* 参数：
{
    phone: '',   // --- 手机号
    verifyCode: ''    // --- 验证码
}
*/
// 手机号登录 --- 郑志会
export const mobileLogin = function (data) {
    return request({
        url: 'sms/mobileLogin',
        method: 'post',
        data
    })
}

/**
* 获取登录账号信息
* 参数：
{
    token: ''   // 返回的 token
}
*/
export const getInfo = function (data) {
    return request({
        url: 'sysUser/getUserInfo',
        method: 'post',
        data
    })
}

/**
* 退出登录
* 参数：
{
    token: ''   // 返回的 token
}
*/
export const logOut = function (data) {
    return request({
        url: 'sysUser/logout',
        method: 'post',
        data
    })
}

// ---- 用户管理 -- 个人资料模块
/**
* 基础信息 -- 获取
* 接口管理员： 俞翔东
* 参数：
{
    "id": ''    // --人员id
}
*/
export const getBasicInfo = function (data) {
    return request({
        url: 'sysUser/getUserBasicInfo',
        method: 'post',
        data
    })
}


/**
* 基础信息 -- 保存
* 接口管理员： 俞翔东
* 参数： 表单数据
{
    "id": ''    // --人员id 必传
    ...
}
*/
export const saveOneInfo = function (data) {
    return request({
        url: 'sysUser/saveUserBasicInfo',
        method: 'post',
        data
    })
}


// ---- 用户管理 -- 工作经验模块
/**
* 工作经验 -- 获取
* 接口管理员： 俞翔东
* 参数：
{
    "userId": ''    // --人员id
}
*/
export const getWork = function (data) {
    return request({
        url: 'userWorkExp/getSysUserWorkExpInfo',
        method: 'post',
        data
    })
}


/**
* 工作经验 -- 保存
* 接口管理员： 俞翔东
* 参数： 表单list
{
    "workExpList": [],
    delIds: ''
}
*/
export const saveWork = function (data) {
    return request({
        url: 'userWorkExp/saveSysUserWorkExpInfo',
        method: 'post',
        data
    })
}


// ---- 用户管理 -- 项目经验模块
/**
* 项目经验 -- 获取
* 接口管理员： 俞翔东
* 参数：
{
    "userId": ''    // --人员id
}
*/
export const getProExp = function (data) {
    return request({
        url: 'userProExp/getSysUserProExpInfo',
        method: 'post',
        data
    })
}


/**
* 项目经验 -- 保存
* 接口管理员： 俞翔东
* 参数： 表单list
{
    "proExpInfoList": [],
    delIds: ''
}
*/
export const saveProExp = function (data) {
    return request({
        url: 'userProExp/saveSysUserProExpInfo',
        method: 'post',
        data
    })
}


// ---- 用户管理 -- 学历模块
/**
* 学历 -- 获取
* 接口管理员： 俞翔东
* 参数：
{
    "userId": ''    // --人员id
}
*/
export const getEduInfo = function (data) {
    return request({
        url: 'userEdu/getSysUserEduInfo',
        method: 'post',
        data
    })
}


/**
* 学历 -- 保存
* 接口管理员： 俞翔东
* 参数： 表单list
{
    "eduInfoList": [],
    delIds: ''
}
*/
export const saveEduInfo = function (data) {
    return request({
        url: 'userEdu/saveSysUserEduInfo',
        method: 'post',
        data
    })
}


// ---- 用户管理 -- 职称模块
/**
* 职称 -- 获取
* 接口管理员： 俞翔东
* 参数：
{
    "userId": ''    // --人员id
}
*/
export const getCareerInfo = function (data) {
    return request({
        url: 'userPractice/getSysUserPracticeInfo',
        method: 'post',
        data
    })
}


/**
* 职称 -- 保存
* 接口管理员： 俞翔东
* 参数： 表单list
{
    "practiceInfoList": [],
    delIds: ''
}
*/
export const saveCareerInfo = function (data) {
    return request({
        url: 'userPractice/saveSysUserPracticeInfo',
        method: 'post',
        data
    })
}


// ---- 用户管理 -- 执业资格模块
/**
* 执业资格 -- 获取
* 接口管理员： 俞翔东
* 参数：
{
    "userId": ''    // --人员id
}
*/
export const getCerInfo = function (data) {
    return request({
        url: 'userCer/getSysUserCerInfo',
        method: 'post',
        data
    })
}


/**
* 执业资格 -- 保存
* 接口管理员： 俞翔东
* 参数： 表单list
{
    "cerInfoList": [],
    delIds: ''
}
*/
export const saveCerInfo = function (data) {
    return request({
        url: 'userCer/saveSysUserCerInfo',
        method: 'post',
        data
    })
}


// ---- 用户管理 -- 其他证书模块
/**
* 其他证书 -- 获取
* 接口管理员： 俞翔东
* 参数：
{
    "userId": ''    // --人员id
}
*/
export const getOtherInfo = function (data) {
    return request({
        url: 'userOtherCer/getSysUserOtherCerInfo',
        method: 'post',
        data
    })
}


/**
* 其他证书 -- 保存
* 接口管理员： 俞翔东
* 参数： 表单list
{
    "otherCerInfoList": [],
    delIds: ''
}
*/
export const saveOtherInfo = function (data) {
    return request({
        url: 'userOtherCer/saveSysUserOtherCerInfo',
        method: 'post',
        data
    })
}

/**
 * 判断当前登录账号是否是运维角色
 */
export const checkedMaintenanceRole = function (data) {
    return request({
        url: '/sysUserMaintenanceRole/checkedMaintenanceRole',
        method: 'post',
        data
    })
}
import request from '../request.js'

// 登录
const loginIn = function (data) {
    return request({
        url: 'sysUser/login',
        method: 'post',
        data
    })
}

// 获取用户信息
const getInfo = function (data) {
    return request({
        url: 'sysUser/getUserInfo',
        method: 'post',
        data
    })
}

// 退出登录
const loginOut = function (data) {
    return request({
        url: 'sysUser/logout',
        method: 'post',
        data
    })
}

// 获取人员列表
const getUsers = function (data) {
    return request({
        url: 'sysUser/getUsers',
        method: 'post',
        data
    })
}

// 启用、禁用人员
const updateUserState = function (data) {
    return request({
        url: 'sysUser/updateUserState',
        method: 'post',
        data
    })
}

// 新增人员
const insertSysUser = function (data) {
    return request({
        url: 'sysUser/insertSysUser',
        method: 'post',
        data
    })
}

// 获取人员信息
const getUserInfo = function (data) {
    return request({
        url: 'sysUser/selectById',
        method: 'post',
        data
    })
}

// 获取用户组人员列表
const getGroupUsers = function (data) {
    return request({
        url: 'sysUser/getGroupUsers',
        method: 'post',
        data
    })
}

const deleteById = function (data) {
    return request({
        url: 'sysUser/deleteById',
        method: 'post',
        data
    })
}

//用户密码还原为默认密码
const updateUserPassRestore = function (data) {
    return request({
        url: 'sysUser/updateUserPassRestore',
        method: 'post',
        data
    })
}

const updateOwnPass = function (data) {
    return request({
        url: 'sysUser/updateOwnPass',
        method: 'post',
        data
    })
}
//获取平台分页列表（郑勇）
const getUserList = function (data) {
    return request({
        url: 'sysUser/getUserList',
        method: 'post',
        data
    })
}
//获取平台分页列表（张卫华）
const getPlatformUserPageList = function (data) {
    return request({
        url: 'sysUser/getPlatformUserPageList',
        method: 'post',
        data
    })
}
//更新启用状态（张卫华）
const updateUserOrEnable = function (data) {
    return request({
        url: 'sysUser/updateUserOrEnable',
        method: 'post',
        data
    })
}
//保存/更新平台用户（张卫华）
const saveOrUpdatePlatformUser = function (data) {
    return request({
        url: 'sysUser/saveOrUpdatePlatformUser',
        method: 'post',
        data
    })
}

// 保存用户新接口 （郑志会）
const saveOrUpdateUser = function (data) {
    return request({
        url: 'sysUser/saveOrUpdateUser',
        method: 'post',
        data
    })
}

// 获取单独的人员信息 （郑志会）
const getOneInfo = function (data) {
    return request({
        url: 'sysUser/getOneInfo',
        method: 'post',
        data
    })
}

// 分页查询用户列表
const getUserPageList = function (data) {
    return request({
        url: 'sysUser/getUserPageList',
        method: 'post',
        data
    })
}

//删除（郑勇）
const removeById = function (data) {
    return request({
        url: 'sysUser/removeById',
        method: 'post',
        data
    })
}
// 获取带选择的平台用户（郑勇）
const importUsers = function (data) {
    return request({
        url: 'sysUser/importUsers',
        method: 'post',
        data
    })
}
// 平台用户导入（郑勇）
const saveImportUsers = function (data) {
    return request({
        url: 'sysUser/saveImportUsers',
        method: 'post',
        data
    })
}

// 单位管理，编辑用户（叶凡）
const updateTenantUser = function (data) {
    return request({
        url: 'sysUser/updateTenantUser',
        method: 'post',
        data
    })
}

//删除（郑勇）
const resetPassword = function (data) {
    return request({
        url: 'sysUser/resetPassword',
        method: 'post',
        data
    })
}

// 校验用户账号和手机
const checkUserInfo = function (data) {
    return request({
        url: 'sysUser/checkUserInfo',
        method: 'post',
        data
    })
}

export {
    loginIn,
    getInfo,
    loginOut,
    getUsers,
    getOneInfo,
    removeById,
    deleteById,
    getUserInfo,
    getUserList,
    importUsers,
    updateOwnPass,
    resetPassword,
    insertSysUser,
    getGroupUsers,
    getUserPageList,
    updateUserState,
    saveImportUsers,
    saveOrUpdateUser,
    updateTenantUser,
    updateUserOrEnable,
    updateUserPassRestore,
    getPlatformUserPageList,
    saveOrUpdatePlatformUser,
    checkUserInfo
}
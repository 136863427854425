/* nprogress 顶部进度条插件
* NProgress.start() 开始显示
* NProgress.set(0.3)  进度从 0.3开始
* NProgress.inc(0.3)    进度条以等比增加 0.3
*
* config:
*   minimum: 0 // 最小值
*   template: '<div></div>'  自定义模板
*   showSpinner: true   是否显示 加载中动画
*
*/
import router from '../router'
import store from '../store'
import { Message } from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getItem, setItem, removeItem } from './storage' // get token from cookie
import getPageTitle from './get-page-title'

// 去除加载图标显示
NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login', '/choseMain', '/choseCompany', '/projectMapOnly'] // no redirect whitelist

// 路由进入前
router.beforeEach(async (to, from, next) => {
    // start progress bar
    NProgress.start()

    // 设置页面标题
    document.title = getPageTitle(to.meta.title)

    // // 获取token 判断用户是否已登录
    const hasToken = getItem('token')

    if (hasToken) {
        if (whiteList.indexOf(to.path) !== -1) {
            // if is logged in, redirect to the home page
            // next({ path: '/' })
            next()
            NProgress.done()
            // 清除token 
            to.path === '/login' ? removeItem('token') : ''
        } else {
            // 判断当前用户是否已拉取完user_info信息
            const hasInfo = store.state.user.userInfo.id
            if (hasInfo) {
                store.dispatch('menu/setRoleBtns', to.path)
                next()
            } else {
                // 请求个人信息
                store.dispatch('user/getUserInfo').then(res => {
                    // console.log(res)
                    store.dispatch('menu/setMenuList', hasToken).then(() => {
                        // 动态添加可访问路由表
                        store.state.menu.activeRoutes.map(x => {
                            router.addRoute('/main', x)
                        })
                        store.dispatch('menu/setRoleBtns', to.path)
                        next({ ...to, replace: true }) // hack方法 确保addRoutes已完成 ,set the replace: true so the navigation will not leave a history record
                    }).catch(err => {
                        Message.error(err)
                        store.dispatch('user/logOut').then(() => {
                            // Message.error(err)
                            next({ path: '/' })
                        })
                        NProgress.done()
                    })
                }).catch(err => {
                    // console.log(err)
                    store.dispatch('user/logOut').then(() => {
                        // Message.error(err)
                        next({ path: '/' })
                    })
                    NProgress.done()
                })
            }
        }
    } else {
        /* has no token*/
        setTimeout(() => {
            if (whiteList.indexOf(to.path) !== -1) {
                // in the free login whitelist, go directly
                if (from.path == '/projectMapOnly') {
                    next({ path: '/projectMapOnly' })
                    return
                }
                next()
            } else {
                store.dispatch('user/logOut', {}).then(() => {
                    next('/login')
                    NProgress.done()

                    // 可在接口中处理
                    setTimeout(() => {
                        Message.error('token 已失效，请重新登录')
                    }, 500)
                })
            }
        }, 500)

    }
})

router.afterEach(() => {
    // finish progress bar
    NProgress.done()
})
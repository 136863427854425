<!--
    描述：附件名称 可预览、可下载
    时间：2021-09-15
    Created by 王帅
-->
<template>
	<div class="file-name-layout">
		<el-dropdown placement="bottom-start"
					 @command="handleClick"
					 class="w100">
			<!-- <span class="el-dropdown-link vxe-cell--label">{{ fileName }}</span> -->
			<TextTooltip refName="fileName"
						 :content="fileName"></TextTooltip>
			<el-dropdown-menu slot="dropdown">
				<template v-if="filePath !== ''">
					<el-dropdown-item command="preview"
									  v-if="isShowPreview && !onlyDownload">预览</el-dropdown-item>
					<el-dropdown-item command="download">下载</el-dropdown-item>
				</template>
				<el-dropdown-item command="delete"
								  v-show="showRemove">删除</el-dropdown-item>
			</el-dropdown-menu>
		</el-dropdown>
	</div>
	<!-- <div class="file-name-layout">
        <span class="ml5 mr5" @click="handleClick('preview')">{{ fileName}}</span>
        <i class="el-icon-download ml5" @click.stop.prevent="handleClick('download')"></i>
    </div> -->
</template>

<script>
import VUE_CONFIG from '../../../vue.config.js'
import TextTooltip from '@/views/Common/TextTooltip'
export default {
	name: '',
	components: { TextTooltip },
	props: {
		// 附件名称
		fileName: {
			type: String,
			default: '',
		},
		// 附件地址
		filePath: {
			type: String,
			default: '',
		},
		onlyDownload: {
			type: Boolean,
			default: false,
		},
		fileSize: {
			type: String,
			default: '',
		},
		// 是否显示删除
		showRemove: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {}
	},
	methods: {
		// 点击预览、下载
		handleClick(type) {
			let uploadUrl = ''
			// 本地环境代理后无法直接获取路径
			if (process.env.VUE_APP_UPLOAD_URL == '/uploadApi') {
				uploadUrl = VUE_CONFIG.devServer.proxy['/uploadApi'].target
			} else {
				uploadUrl = process.env.VUE_APP_UPLOAD_URL
			}

			if (type === 'preview') {
				if (parseInt(this.fileSize) / 1024 / 1024 > 10) {
					this.$message.warning('该附件过大，请下载后预览。')
					return
				}
				window.open(
					this.$previewUrl + uploadUrl + this.filePath,
					'_blank'
				)
			} else if (type === 'delete') {
				this.$emit('doDelete')
			} else {
				window.open(uploadUrl + this.filePath, '_blank')
			}
		},
	},
	computed: {
		// 是否显示预览
		isShowPreview() {
			let arr = this.fileName.split('.')
			let fileFormat = arr[arr.length - 1]
			// 如果是可预览的文件类型
			return this.viewAcceptType.includes(fileFormat.toUpperCase())
		},

		// 可预览格式
		viewAcceptType() {
			return [
				'JPG',
				'JPEG',
				'PNG',
				'PDF',
				'GIF',
				'EXCEL',
				'DOC',
				'DOCX',
				'TXT',
				'XLS',
				'XLSX',
				'PPT',
				'PPTX',
				'XML',
				'DWG',
			]
		},
	},
}
</script>

<style scoped lang="less">
.file-name-layout {
	display: inline-block;
	color: #60adf9;
	cursor: pointer;
	margin-left: 5px;
	width: 100%;

	i {
		vertical-align: middle;
	}
}
</style>

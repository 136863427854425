<!--
    描述：布局组件 --- 头部
    时间：2021-03-24
    Created by 王帅
-->
<template>
	<div class="app-header">
		<div class="logo-container text-overflow">
			<span class="pdl10">合肥东部新中心建设管理办公室</span>
			
			 <!-- <img src="../../assets/images/logo_jk_white.png"
				 alt="" /> -->
		</div>

		<div class="right-info">
			<span class="mr20"
				  v-if="companyName !== ''">{{ companyName }}, 欢迎您！</span>

			<!-- 全屏 -->
            <screen-full class="screen-btn"></screen-full>
			
			<el-dropdown @command="handleCommand"
						 class="info-text">
				<span class="el-dropdown-link">
					{{ userInfo.sname }}<i class="el-icon-arrow-down el-icon--right"></i>
				</span>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item command="userInfo">个人资料</el-dropdown-item>
					<el-dropdown-item command="password">修改密码</el-dropdown-item>
					<el-dropdown-item command="logOut">退出登录</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
		</div>
		<!-- 弹窗 -->
		<el-dialog :title="dialogObj.title"
				   :visible.sync="dialogObj.show"
				   :width="dialogObj.width"
				   :close-on-click-modal="false"
				   center
				   @close="closeDialog"
				   class="edit-password">
			<!-- 新增模板 -->
			<el-form ref="passRef"
					 :model="passForm"
					 label-width="100px"
					 :rules="passRules">
				<el-row>
					<el-col :span="22">
						<el-form-item label="旧密码："
									  prop="oldPassword">
							<el-input v-model.trim="passForm.oldPassword"
									  minlength="8"
									  maxlength="16"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="22">
						<el-form-item label="新密码："
									  prop="newPassword"
									  :rules="passwordRule">
							<el-input v-model.trim="passForm.newPassword"
									  auto-complete="off"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="22">
						<el-form-item label="确认密码："
									  prop="trueNewPassword"
									  :rules="truePasswordRule">
							<el-input @blur="checkPass"
									  v-model.trim="passForm.trueNewPassword"></el-input>
						</el-form-item>
						<div class="leftMar">
							<p class="passwordTip">
								<i class="el-icon-warning"></i>
								长度6-20字符,只能包含字母、数字及标点符号(除空格),至少包含2种
							</p>
						</div>
					</el-col>
				</el-row>
			</el-form>
			<!-- 底部按钮 -->
			<div slot="footer">
				<el-button size="small"
						   class="btn"
						   @click="closeDialog">取 消
				</el-button>
				<el-button size="small"
						   class="btn btn-blue"
						   type="primary"
						   :loading="btnLoading"
						   @click="sumbitPass">确 定
				</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { getItem } from '@/utils/storage.js'
import { updateOwnPass } from 'api/system/user'
import screenFull from '@/components/screenFull'
export default {
	components: {
		screenFull
	},
	data() {
		let checkPassword = (rule, value, callback) => {
			let pattern = /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?![,\.#%'\+\*\-:;^_`]+$)[,\.#'\+\*\-:;`0-9A-Za-z]{6,20}$/
			if (value != '' && pattern.test(value)) {
				return callback()
			} else {
				return callback(new Error('密码格式不对'))
			}
		}
		return {
			// 新增弹窗
			dialogObj: {
				show: false,
				title: '修改密码',
				width: '800px',
				type: '',
			},
			btnLoading: false,
			passForm: {
				oldPassword: '',
				newPassword: '',
				trueNewPassword: '',
			},
			passRules: {
				oldPassword: [
					{ required: true, message: '不能为空...', trigger: 'blur' },
				],
			},
			passwordRule: [
				{ required: true, message: '不能为空...', trigger: 'blur' },
				{ validator: checkPassword, trigger: ['blur', 'change'] },
			],
			truePasswordRule: [
				{ required: true, message: '不能为空...', trigger: 'blur' },
				{ validator: checkPassword, trigger: ['blur', 'change'] },
			],
		}
	},
	methods: {
		...mapActions('user', ['logOut']),

		// 比对两次新密码是否一致
		checkPass() {
			let obj = this.passForm
			if (
				obj.trueNewPassword != obj.newPassword &&
				obj.newPassword != '' &&
				obj.trueNewPassword != ''
			) {
				this.$message.error('两次密码不一致，请重新输入！')
				obj.trueNewPassword = ''
			}
		},

		// 确认修改
		sumbitPass() {
			this.btnLoading = true
			this.$refs.passRef.validate((valid) => {
				if (valid) {
					let params = this.$utils.clone(this.passForm, true)
					updateOwnPass(params).then((res) => {
						if (res.code == 0) {
							this.$message.success('保存成功，请重新登录')
							if (getItem('type') && getItem('type') !== 'undefined') {
								this.$router.push({
									path: '/login',
									query: { type: getItem('type') },
								})
							} else {
								this.$router.push({
									path: '/login',
								})
							}
						} else {
							this.$message.error(res.msg)
							this.btnLoading = false
						}
					}).catch(err => {
						this.btnLoading = false
					})
				} else {
					this.$message.error('请校验表单！')
					this.btnLoading = false
				}
			})
		},

		// 取消修改
		closeDialog() {
			this.$refs.passRef.resetFields()
			this.dialogObj.show = false
		},

		// 切换选项
		handleCommand(command) {
			switch (command) {
                // 退出
				case 'logOut':
					this.logOut()
						.then(() => {
							this.$router.push({
								path: '/login',
							})

							setTimeout(() => {
								this.$message.success('退出成功!')
							}, 500)
						})
						.catch(() => {
							// this.btnLoading = false
						})
					break
                // 修改密码
				case 'password':
					this.dialogObj.show = true
					break
                // 个人资料
                case 'userInfo':
                    this.$router.push({
						path: '/userInfo',
					})
                    break
				case 'download':
					window.location.href = '/安招采承诺书.docx'
					break
			}
		},
	},
	computed: {
		...mapState('user', {
			userInfo: (state) => state.userInfo,
		}),

		// 公司名称
		companyName() {
			return getItem('companyName') ? getItem('companyName') : ''
		},
	},
};
</script>

<style scoped lang="less">
.logo-container {
	height: 100%;
	display: flex;
	align-items: center;

	img {
		height: 100%;
		// height: 40px;
		margin-right: 10px;
		// margin-left: 24px;
	}

	span {
		display: inline-block;
	}
}

.leftMar {
	text-align: left;
	margin: 0 0 10px 100px;

	.passwordTip {
		color: #ccc;
		font-size: 12px;

		i {
			font-size: 16px;
		}
	}
}

.edit-password {
	.el-form-item {
		margin-bottom: 20px !important;
	}
}

.btn {
	height: 40px !important;
	padding: 0 50px !important;
}

.btn-blue:focus {
	color: #fff !important;
	background-color: #256ccb !important;
}

.screen-btn {
	display: inline-block;
    margin-right: 15px;
}
</style>

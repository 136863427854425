<!--
    描述：全屏功能页面
    时间：2020-04-22
    Created by ws
-->
<template>
	<div class="screenfull-main">
		<svg-icon :icon-class="isFullscreen?'exit-fullscreen':'fullscreen'"
				  @click="handleClick" />
	</div>
</template>

<script>
import screenfull from 'screenfull'

export default {
	name: 'Screenfull',
	data() {
		return {
			isFullscreen: false
		}
	},
	mounted() {
		this.init()
	},
	methods: {
        // 点击全屏按钮
		handleClick() {
            // console.log(screenfull)
			if (!screenfull.isEnabled) {
				this.$message({
					message: '你的浏览器暂不支持 该功能',
					type: 'warning'
				})
				return false
			}
			screenfull.toggle()
        },
        
        // 初始 渲染插件
		init() {
			if (screenfull.isEnabled) {
				screenfull.on('change', () => {
					this.isFullscreen = screenfull.isFullscreen
				})
			}
		}
	}
}
</script>

<style scoped lang="less">
.screenfull-main {
	cursor: pointer;
}
</style>

<!--
    描述： tooltip
    时间：2021-05-21
    Created by zl
-->
<template>
    <div class="text-tooltip">
        <el-tooltip
            class="item"
            popper-class="bm-tooltip"
            effect="dark"
            :disabled="isShowTooltip"
            :content="content"
            :placement="direction"
        >
            <p
                class="over-flow"
                :class="className"
                @mouseover="onMouseOver(refName)"
            >
                <span :ref="refName">{{ content || '无' }}</span>
            </p>
        </el-tooltip>
    </div>
</template>

<script>
export default {
    name: 'textTooltip',
    props: {
        // 显示的文字内容
        content: {
            type: String,
            default: () => {
                return ''
            },
        },
        // 外层框的样式，在传入的这个类名中设置文字显示的宽度
        className: {
            type: String,
            default: () => {
                return ''
            },
        },
        // 为页面文字标识（如在同一页面中调用多次组件，此参数不可重复）
        refName: {
            type: String,
            default: () => {
                return ''
            },
        },
        // tooltip 的展开方向
        direction: {
            type: String,
            default: () => {
                return 'top'
            },
        }
    },
    data() {
        return {
            isShowTooltip: true,
        }
    },
    methods: {
        onMouseOver(str) {
            if (str && this.$refs[str]) {
                let parentWidth = this.$refs[str].parentNode.offsetWidth
                let contentWidth = this.$refs[str].offsetWidth
                // 判断是否开启tooltip功能
                if (contentWidth > parentWidth) {
                    this.isShowTooltip = false
                } else {
                    this.isShowTooltip = true
                }
            }
        },
    },
}
</script>

<style lang="less" scoped>
.over-flow {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.wid190 {
    width: 190px;
}
</style>
const state = {
    clientSize: {}, // 页面宽高
    fullScreenLoading: false,   // 全局loading 状态
    isCollapse: false,  // 左侧菜单栏 是否折叠
    isBack: false,
    curMenuName: '',    // 当前菜单名称
}

const mutations = {
    SET_CLIENT_SIZE: (state, obj) => {
        state.clientSize = obj
    },
    SET_FULL_LOADING: (state, value) => {
        state.fullScreenLoading = value
    },
    SET_COLLAPSE: (state, value) => {
        state.isCollapse = value
    },
    SET_ISBACK: (state, status) => {
        state.isBack = status
    },
    SET_MENUNAME: (state, name) => {
        state.curMenuName = name
    }
}

const actions = {
    // 设置窗口
    setClientSize({ commit }, obj) {
        commit('SET_CLIENT_SIZE', obj)
    },
    // 更新全局加载框状态
    setFullLoading({ commit }, value) {
        commit('SET_FULL_LOADING', value)
    },
    // 设置左侧菜单栏 折叠状态
    setCollapse({ commit }, value) {
        commit('SET_COLLAPSE', value)
    },
    // 设置是否是返回的状态
    setIsBack({ commit }, status) {
        commit('SET_ISBACK', status)
    },
    // 设置当前的菜单
    setMenuName({ commit }, name) {
        commit('SET_MENUNAME', name)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}